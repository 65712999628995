import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { isLoggedIn } from "utils";

// ********************************************* Ip Access List ***********************************************
export const getIPAccessList = async (data) => {
  return decryptData(
    await axios.get(
      `/get-ip-access-list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const AddAccessIpAddress = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/add-ip-access`, {
      reqData: encryptData({
        ...data,
        access_token: token,
      }),
    })
  );
};
export const DeleteAccessIpAddress = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/delete-ip-access`, {
      reqData: encryptData({
        ...data,
        access_token: token,
      }),
    })
  );
};


// **************************************** IP Access Log **************************************************
export const getIPAccessLogList = async (data) => {
  return decryptData(
    await axios.get(
      `/get-ip-access-list-log-report?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
