import IconButton from "components/Button/IconButton";
import NotFound from "components/not-found/NotFound";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ModalBox from "components/modal/ModalBox";
import { useNavigate } from "react-router-dom";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import { Button } from "components/Button/Button";
import { Input, TextArea } from "components/Form";
import toast from "react-hot-toast";
import {
  AddAccessIpAddress,
  DeleteAccessIpAddress,
  getIPAccessList,
} from "services/IPAccessManagement";
import { dateFormat, dateTimeFormat , dateipFormat} from "utils";
import { Checkbox, FormControlLabel } from "@mui/material";
import { getUserIpAddress } from "services/auth";
import { RemoveRedEye } from "@mui/icons-material";

export default function IpAccessList() {
  const navigate = useNavigate();
  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [activePage, setActivePage] = useState(1);

  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [data, setData] = useState({});
  const [comment, setComment] = useState("");
  const [errorField, setErrorField] = useState({});
  const [flag, setFlag] = useState(false);

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();

  //   ************************************* Fetch Records *******************************************
  const fetchRecord = async () => {
    showLoader();
    try {
      const res = await getIPAccessList({
        page: activePage,
        // search: search,
        // limit: pageLimit,
      });
      hideLoader();
      if (res.code === 200) {
        const { rows, count } = res.data.ipaccesslist;
        setRecord(rows || []);
        setTotal(count || 0);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
    hideLoader();
  };
  useEffect(() => {
    fetchRecord();
  }, [activePage]);

  //   *************************************** fetch IP Address ***********************************************
  useEffect(() => {
    if (flag) {
      (async () => {
        try {
          const response = await getUserIpAddress();
          setData({ ...data, address: response.data.ip });
        } catch (error) {
          console.error("Error fetching IP:", error);
        }
      })();
    } else {
      setData({ ...data, address: "" });
    }
  }, [flag]);

  //   ******************************************* Handle Ip Submit ***************************************************

  const handleValidation = () => {
    let errorField = {};
    let formIsValid = true;
    if (!data.address) {
      errorField["address"] = "Please Enter IP Address";
      formIsValid = false;
    }
    if (!data.name) {
      errorField["name"] = "Please Enter Name";
      formIsValid = false;
    }

    setErrorField(errorField);
    return formIsValid;
  };

  const handleIpSubmit = async () => {
    if (handleValidation()) {
      showLoader();
      try {
        const res = await AddAccessIpAddress({ name: data.name, ipaddress: data.address, comment: data.comment });
        hideLoader();
        if (res.code === 200) {
          toast.success(res.message);
          setAddModal(false);
          fetchRecord();
          setFlag(false);
        } else {
          setAddModal(false);
          fetchRecord();
          setFlag(false);
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
      }
      hideLoader();
    }
  };
  //   *********************************** Handle Ip delete **********************************************
  const handleIpDelete = async () => {
    showLoader();
    try {
      const res = await DeleteAccessIpAddress({
        id: data.id,
        ipaddress: data.ipaddress,
        name: data.name,        // Pass the name
        comment: data.comment,  // Pass the comment
      });
      hideLoader();
      if (res.code === 200) {
        toast.success(res.message);
        setDeleteModal(false);
        fetchRecord();
      } else {
        toast.error(res.message);
        setDeleteModal(false);
        fetchRecord();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
    hideLoader();
  };
  

  return (
    <>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="search_wrap">
                <div className="SearchBox mt-1">
                  {/* <div style={{ marginRight: "10px" }}>
                    <Input
                      className={"form-control"}
                      placeholder="Search Here"
                      value={search}
                      onChange={({ target }) => setSearch(target.value)}
                      type="text"
                    />
                  </div> */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setAddModal(true);
                      }}
                    >
                      Add IP Address
                    </Button>
                  </div>
                </div>
              </div>
              <h4 className="Title">IP Access List</h4>
            </div>

            <div className="TableList overflow-auto table-responsive">
              {loader}
              <Table bordered className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Ip Address</th>
                    <th>Date And Time</th>
                    <th className="text-center">Comment</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {record && record.length > 0 ? (
                    record.map(({ id, name, ipaddress, addedon, comment }, i) => (
                      <tr key={i}>
                        <td style={{ width: "100px" }}>
                          {++i + (activePage - 1) * 1}
                        </td>
                        <td>{name}</td>
                        <td>{ipaddress}</td>
                        <td>{dateipFormat(addedon)}</td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <IconButton
                              aria-label="view"
                              className="px-1 py-0"
                              onClick={() => {
                                setCommentModal(true)
                                setComment(comment || "")
                              }}
                            >
                              <RemoveRedEye sx={{ color: "#4971B2" }} />
                            </IconButton>
                          </div>
                        </td>
                        <td style={{ width: "100px" }}>
                          <div className="d-flex justify-content-center">
                            {/* <IconButton
                              aria-label="view"
                              className="px-1 py-0"
                              onClick={() => {
                                navigate("/prelims/gs-pyq/edit-prelims-pyq");
                                //   localStorage.setItem("id", PYQID);
                              }}
                            >
                              <EditIcon sx={{ color: "#4971B2" }} />
                            </IconButton> */}
                            <IconButton
                              aria-label="view"
                              className="px-1 py-0"
                              onClick={() => {
                                setDeleteModal(true);
                                setData({ id, ipaddress, name, comment });
                              }}
                            >
                              <DeleteIcon sx={{ color: "#ff0000" }} />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NotFound cols={9} />
                  )}
                </tbody>
              </Table>
            </div>
            {/* {total >= 10 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={limit}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={(e) => setActivePage(e)}
                />
              </div>
            ) : null} */}
          </div>
        </div>
      </div>

      {/* // ********************************* Comment Modal ************************************* */}
      <ModalBox
        status={commentModal}
        onClose={() => setComment(false)}
        custom
        closeIcon={false}
        size={"sm"}
        title={"Comment"}
        description={
          <>
            <div className="form-group row mt-1">
              <div className="col-sm-12 col-form-label order-1 d-flex flex-column justify-content-end">
                <div className="row">
                  <div className="col-sm-12 col-form-label">
                    <TextArea
                      type="text"
                      name="comment"
                      value={comment || "No Comment Found"}
                      // placeholder="Enter comment"
                      className={`form-control `}
                      rows={4}
                      label={"Comment"}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        customizedFooter={
          <div>
            <Button onClick={() => setCommentModal(false)} {...submitButtonElement}>
              Close
            </Button>
          </div>
        }
      />
      {/* // ********************************* Add IP Address Modal ************************************* */}
      <ModalBox
        status={addModal}
        onClose={() => setAddModal(false)}
        // custom
        // closeIcon
        size={"sm"}
        title={"Add IP Address"}
        description={
          <>
            <div className="form-group row mt-1">
              <div className="col-sm-12 col-form-label order-1 d-flex flex-column justify-content-end">
                <div className="row">
                  {/* <div className="col-sm-12 col-form-label">
                    <FormControlLabel
                      control={<Checkbox checked={flag} />}
                      label={"Set My IP Address"}
                      onChange={() => setFlag(!flag)}
                    />
                  </div> */}
                  <div className="col-sm-12 col-form-label">
                    <Input
                      type="text"
                      name="address"
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      placeholder="Enter IP Address"
                      className={`form-control`}
                      label={"IP Address"}
                      value={data.address}
                      error={errorField.address}
                      required
                    />
                    {/* <br /> */}
                    {/* <label
                      htmlFor=""
                      style={{ border: "1px solid #ccc", padding: "10px" }}
                      >
                      <span className="text-danger">Note : </span>To check your
                      IP address, open any browser and visit the URL"{" "}
                      <b> https://ipapi.co/json/ </b> "From the response, locate
                      the ip parameter; it will provide your IP address.
                    </label>
                    <label
                      htmlFor=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      -- Or --
                      </label>
                      <label
                      htmlFor=""
                      style={{ border: "1px solid #ccc", padding: "10px" }}
                    >
                    <span className="text-danger">Note : </span>Please Use
                      these " <b>curl ifconfig.me</b> "command in command prompt
                      and get IP Address.
                    </label> */}
                  </div>
                  <div className="col-sm-12 col-form-label">
                    <Input
                      type="text"
                      name="name"
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      placeholder="Enter Name"
                      className={`form-control`}
                      label={"Name"}
                      value={data.name}
                      error={errorField.name}
                      required
                    />
                  </div>
                  <div className="col-sm-12 col-form-label">
                    <TextArea
                      type="text"
                      name="comment"
                      value={data.comment}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      placeholder="Enter comment"
                      className={`form-control `}
                      rows={4}
                      label={"Comment"}
                    // readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        customizedFooter={
          <div>
            <Button onClick={handleIpSubmit} {...submitButtonElement}>
              Submit
            </Button>
          </div>
        }
      />

      {/* ***************************** Delete Ip Address Modal ********************************** */}
      <ModalBox
        status={deleteModal}
        onClose={() => setDeleteModal(false)}
        title={"Delete IP Address"}
        size={"md"}
        description={"Are you sure want to delete this IP Address ?"}
        customizedFooter={
          <Button onClick={handleIpDelete} {...submitButtonElement}>
            Delete
          </Button>
        }
      />
    </>
  );
}
