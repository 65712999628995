import { normalDecryptData, normalEncryptData } from "config/config";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";

export function isLoggedIn() {
  let x,
    accessToken,
    y,
    i,
    cookieArr = document.cookie.split(";");
  for (i = 0; i < cookieArr.length; i++) {
    x = cookieArr[i].substr(0, cookieArr[i].indexOf("="));
    y = cookieArr[i].substr(cookieArr[i].indexOf("=") + 1);
    x = x.replace(/^\s+|\s+$/g, "");
    if (x === process.env.REACT_APP_AUTH) {
      accessToken = decodeURIComponent(y);
    }
  }
  return normalDecryptData(accessToken);
}

export function multiPartData(data) {
  let multiPart = new FormData();
  for (let key in data) {
    multiPart.append(key, data[key]);
  }

  return multiPart;
}

export function checkPassword(str) {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}

export function capitalizeFirstLetter(string) {
  //return ;
  return string;
  //return str.charAt(0).toUpperCase() + str.slice(1);
}

export function dateFormat(date) {
  if (date) {
    return new Date(Date.parse(date)).toLocaleString("default", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  } else {
    return "-- --- ----";
  }
  // return moment(date).format("DD/MM/YYYY");
}

export function dateipFormat(date) {
  if (date) {
    return new Date(Date.parse(date)).toLocaleString("default", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  } else {
    return "-- --- ---- --:--:--";
  }
  // return moment(date).format("DD/MM/YYYY HH:mm:ss");
}


export function reportdateFormat(date) {
  if (date) {
    return new Date(Date.parse(date)).toLocaleString("default", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 24-hour format
      timeZone: "Asia/Kolkata" // Adjust according to your specific time zone
    });
  } else {
    return "-- --- ----";
  }
  // return moment(date).format("DD/MM/YYYY");
}

export const simpleDateFormat = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  } else {
    return "----- -- --";
  }
};

export const simpleDateFormat1 = (date) => {
  if (date) {
    return moment(date).format("DD-MM-YYYY");
  } else {
    return "----- -- --";
  }
};

export const simpleTimeFormat = (date) => {
  if (date) {
    return moment(date).format("HH:mm");
  } else {
    return "---- -- --";
  }
};

export const simpleTimeFormat12Hour = (time) => {
  if (time) {
    return moment(time, ["HH:mm"]).format("hh:mm A");
  } else {
    return "--:-- --";
  }
};

export function dateTimeFormat(date) {
  return moment(date).format("DD/MM/YYYY H:mm:ss");
}

export function ShortString(string) {
  return string.substr(-100);
}

//get device status online ofline time
export function deviceOnlineStatus(deviceStatusTime) {
  let currentTime = moment(new Date());
  let lastUpdatedTime = moment(new Date(parseInt(deviceStatusTime)));
  let totalMinutes = currentTime.diff(lastUpdatedTime, "minutes");
  let deviceStatus;
  if (totalMinutes > 10) {
    deviceStatus = false;
  } else {
    deviceStatus = true;
  }
  return deviceStatus;
}

export function errorMsg() {
  return "Something wents wrong, please try again";
}

export function validateEmail(mail) {
  // eslint-disable-next-line no-useless-escape
  var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (mail.match(mailFormat)) {
    return true;
  } else {
    return false;
  }
}

// this function checks dat is available or not
export function dataIsAvailable(data) {
  return data ? data : "N/A";
}

// this function is used for character limitation
export function charLimit(data, limit) {
  return data?.length > limit ? data.substring(0, limit + 1) + "..." : data;
}

// this function is used to get arrays of counts
export function countArray(limit) {
  let arr = [];
  for (let i = 1; i <= limit; i++) {
    arr.push(i);
  }
  return arr;
}

// this function is used to get arrays of alphabets
export function alphabetsArray(limit) {
  const ALPHABETS = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E",
    6: "F",
    7: "G",
    8: "H",
    9: "I",
    10: "J",
    11: "K",
    12: "L",
    13: "M",
    14: "N",
    15: "O",
    16: "P",
    17: "Q",
    18: "R",
    19: "S",
    20: "T",
    21: "U",
    22: "V",
    23: "W",
    24: "X",
    25: "Y",
    26: "Z",
  };
  let arr = [];
  for (let i = 1; i <= limit; i++) {
    arr.push(ALPHABETS[i]);
  }
  return arr;
}

// this function is used to get arrays of counts
export function countArrayWithString(limit, str) {
  let arr = [str];
  for (let i = 1; i <= limit; i++) {
    arr.push(i);
  }
  return arr;
}

export const timeConverter = (time, date) => {
  if (time) {
    const newFormatDate = new Date(date).toLocaleString("en-US", {
      timeZone: "UTC",
    });
    const fullYear = date
      ? new Date(newFormatDate).getFullYear()
      : new Date().getFullYear();
    const month = date
      ? new Date(newFormatDate).getMonth()
      : new Date().getMonth();
    const newDate = date
      ? new Date(newFormatDate).getDate()
      : new Date().getDate();

    const editTime = time.split(":");
    return new Date(fullYear, month, newDate, editTime[0], editTime[1]);
  } else {
    return new Date();
  }
};

export function customSetHours(dt, h) {
  var s = /(\d+):(\d+) (.+)/.exec(h);
  dt.setHours(s[3] === "pm" ? 12 + parseInt(s[1], 10) : parseInt(s[1], 10));
  dt.setMinutes(parseInt(s[2], 10));
  return dt;
}

// get targets year
export const getTargetYears = (year = 3) => {
  const currYear = new Date().getFullYear();
  const yearArr = [];
  // iterate year argument
  for (let i = 0; i < year; i++) {
    const data = new Date();
    data.setFullYear(currYear + i);
    yearArr.push(data.getFullYear());
  }
  return yearArr;
};

// get targets year with previous date
export const getTargetYearsWithDate = (date) => {
  let mainDate = date;
  if (!date) {
    mainDate = new Date();
  }
  const currYear = new Date().getFullYear();
  const mainYear = new Date(mainDate).getFullYear();
  if (mainYear > currYear) {
    const yearArr = [];
    // iterate year argument
    for (let i = currYear; i <= mainYear; i++) {
      const data = new Date();
      data.setFullYear(i);
      yearArr.push(data.getFullYear());
    }
    return yearArr;
  } else {
    // add 3 more years
    let year = currYear - mainYear + 3;

    const yearArr = [];
    // iterate year argument
    for (let i = 0; i < year; i++) {
      const data = new Date();
      data.setFullYear(mainYear + i);
      yearArr.push(data.getFullYear());
    }
    return yearArr;
  }
};

//   this function is used for organized data with validation(for addon list)
export const getOrganizedData = (arr, obj) => {
  let newArray = [];
  arr.forEach((data) => {
    const splitData = data.split(",");
    newArray.push({
      addon_id: splitData[1],
      addon_course_id: splitData[0],
      price: obj[data + ",price"],
      name: obj[data + ",name"],
    });
  });
  const validation = newArray.map((data) => {
    if (!data.price || !data.name) {
      return true;
    } else {
      return false;
    }
  });
  return { arr: newArray, validation: validation.some((data) => data) };
};

export const getOrganizedDataForCenter = (arr, obj) => {
  let newArray = [];
  arr.forEach((data) => {
    newArray.push({
      center_id: data.value,
      rc_new_student_fee: obj[data.value + ",rcOldStudentFee"],
      rc_old_student_fee: obj[data.value + ",rcNewStudentFee"],
      affiliate_commission_with_classroom:
        obj[data.value + ",affiliateCommissionWithClass"],
      affiliate_commission_without_classroom:
        obj[data.value + ",affiliateCommissionWithoutClass"],
      affiliate_max_discount: obj[data.value + ",affiliateMaxDiscount"],
    });
  });
  const validation = newArray.map((data) => {
    if (
      !data.rc_new_student_fee ||
      !data.rc_old_student_fee ||
      !data.affiliate_commission_with_classroom ||
      !data.affiliate_commission_without_classroom ||
      !data.affiliate_max_discount
    ) {
      return true;
    } else {
      return false;
    }
  });
  return { arr: newArray, validation: validation.some((data) => data) };
};

export const objectTypeChecker = (data) => {
  return typeof data === "object" ? true : false;
};

export function wrapImagesWithFigure(rowData) {
  // const parser = new DOMParser();
  // const doc = parser.parseFromString(rowData, "text/html");
  // const imgTags = doc.getElementsByTagName("img");

  // for (let i = 0; i < imgTags.length; i++) {
  //   const img = imgTags[i];
  //   const figure = doc.createElement("figure");
  //   const rowDiv = parser
  //     .parseFromString(
  //       '<div class="" data-block="true" data-editor="78gim" data-offset-key="dqi6k-0-0"><div data-offset-key="dqi6k-0-0" class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span data-offset-key="dqi6k-0-0"><br data-text="true"></span></div></div>',
  //       "text/html"
  //     )
  //     .querySelector("div");

  //   img.parentNode.insertBefore(figure, img);
  //   figure.appendChild(img);
  //   figure.insertAdjacentElement("afterend", rowDiv);
  // }

  // return doc.documentElement.innerHTML;
  return rowData;
}

export const fixedImageDimension = (data) => {
  var tempElement = document.createElement("div");
  tempElement.innerHTML = data;

  var images = tempElement.querySelectorAll("img");

  for (var i = 0; i < images.length; i++) {
    if (images[i].style.height === "" || images[i].style.width === "") {
      images[i].style.height = "250px";
      images[i].style.width = "250px";
    }
  }

  return tempElement.innerHTML;
};

export const rawToHtmlConverter = (data) => {
  return fixedImageDimension(draftToHtml(convertToRaw(data)));
};

// course access with startDate
export function dateByAccess(startDate) {
  if (startDate) {
    const startDateUTC = new Date(startDate).toLocaleString("en-US", {
      timeZone: "UTC",
    });
    const startDateFormat = moment(startDateUTC);
    const currentDate = moment();
    return currentDate >= startDateFormat;
  } else {
    return false;
  }
}

// course expire with endDate
export function dateByExpire(endDate) {
  if (endDate) {
    const endDateUTC = new Date(endDate).toLocaleString("en-US", {
      timeZone: "UTC",
    });
    const endDateFormat = moment(endDateUTC);
    const currentDate = moment();
    return currentDate <= endDateFormat;
  } else {
    return false;
  }
}

export function groupByKey(array, key) {
  const arr = array.reduce((acc, obj) => {
    const property = obj[key];
    acc[property] = acc[property] || [];
    acc[property].push({ label: obj.name, value: obj.id });
    return acc;
  }, {});
  let completeArr = [];
  for (let a in arr) {
    completeArr.push({ label: a, options: arr[a] });
  }
  return completeArr;
}

export function getLocalConfig(key, type = "config") {
  return normalDecryptData(sessionStorage.getItem(type))
    ? JSON.parse(normalDecryptData(sessionStorage.getItem(type)))[key]
    : "";
}

export function setLocalConfig(key, value, type = "config") {
  let configData = normalDecryptData(sessionStorage.getItem(type))
    ? JSON.parse(normalDecryptData(sessionStorage.getItem(type)))
    : {};

  configData = {
    ...configData,
    [key]: value,
  };

  sessionStorage.setItem(type, normalEncryptData(JSON.stringify(configData)));
}

// convert minutes to hours,minute and seconds
export function convertMinutesToHoursMinutesSeconds(minutes) {
  if (isNaN(minutes) || minutes < 0) {
    return "Invalid input";
  }

  // if data is 0
  if (minutes <= 0) {
    return "0 sec";
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);
  const remainingSeconds = Math.round((minutes % 1) * 60); // Round to the nearest second

  let result = "";

  if (hours > 0) {
    result += hours + " hr";

    result += ", ";
  }

  if (remainingMinutes > 0) {
    result += remainingMinutes + " min";

    if (remainingSeconds > 0) {
      result += ", ";
    }
  }

  if (remainingSeconds > 0) {
    result += remainingSeconds + " sec";
  }

  return result;
}

export const simpleDateFormatForSearch = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  } else {
    return "";
  }
};
