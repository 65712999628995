import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const NotAutherized = lazy(() => import("components/not-found/NotAutherized"));

export default function ErrorRouting() {
  return (
    <>
      <Routes>
        <Route path="/" element={<NotAutherized />} />
        <Route path="*" element={<NotAutherized />} />
      </Routes>
    </>
  );
}
