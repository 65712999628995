import React, { lazy } from "react";

// MUI Icons
import EventIcon from "@mui/icons-material/Event";
import SearchIcon from "@mui/icons-material/Search";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Groups3Icon from "@mui/icons-material/Groups3";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import SubjectIcon from "@mui/icons-material/Subject";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import LanguageIcon from "@mui/icons-material/Language";
import DiscountIcon from "@mui/icons-material/Discount";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HelpIcon from "@mui/icons-material/Help";
import HubIcon from "@mui/icons-material/Hub";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import SummarizeIcon from "@mui/icons-material/Summarize";
import QuizIcon from "@mui/icons-material/Quiz";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import AssessmentIcon from "@mui/icons-material/Assessment";
import IPAccessIcon from "@mui/icons-material/AcUnitOutlined";
import ViewSiteSetting from "pages/WebsiteManagement/SiteSetting/ViewSiteSetting";
import LiveLectureDetailsHistory from "pages/Reports/LectureDetails/LiveLectureDetailsHistory";
import LectureAttemptLog from "pages/Reports/LectureReport/LectureAttemptLog";
import KnoxApiErrorLog from "pages/Reports/KNOXReports/KnoxApiErrorLog";
import PiracyAttemptReport from "pages/Reports/LectureReport/PiracyAttemptReport";

import StudentWatchingHours from "pages/StudentManagement/Students/StudentWatchingHours";
import StudentCourseTestReport from "pages/StudentManagement/Students/StudentCourseTestReport";
import AffiliateBillingDetails from "pages/BillingManagement/AffiliateBillingDetails";
import PGMaster from "pages/PGManagement/PGMaster";
import AddPGMaster from "pages/PGManagement/AddPGMaster";
import EditPGMaster from "pages/PGManagement/EditPGMaster";
import AffiliatePG from "pages/PGManagement/AffiliatePG";
import AdmissionSummaryReport from "pages/Reports/CourseAccessReport/AdmissionSummaryReport";
import AffiliateStudentCourseAccessReport from "pages/Reports/CourseAccessReport/AffiliateStudentCourseAccessReport";
import UpcomingExamAndCourseDisplayOrder from "pages/UpcomingExamandCourseDisplayOrder/UpcomingExamAndCourseDisplayOrder";
import AssignmentIcon from "@mui/icons-material/Assignment"; // Mains Management
import CategoryIcon from "@mui/icons-material/Category"; // Optional Management
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"; // Interview Management
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"; // Prelims Management

//import CourseMapping from "pages/CourseManagement/CourseMapping/CourseMapping";
//import LectureDetailsHistory from "pages/Reports/LectureDetailsHistory/LectureDetailsHistory";

const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"));
const ChangePassword = lazy(() =>
  import("pages/ChangePassword/ChangePassword")
);



// ----------------------- Course Management -----------------------//
const UpdateCourseCategory = lazy(() =>
  import("pages/CourseManagement/CourseCategory/UpdateCourseCategory")
);
const SingleCourseCategory = lazy(() =>
  import("pages/CourseManagement/CourseCategory/SingleCourseCategory")
);
const AddCourse = lazy(() =>
  import("pages/CourseManagement/Courses/AddCourse")
);
const SingleBillingCourse = lazy(() =>
  import("pages/CourseManagement/BillingCourse/SingleBillingCourse")
);
const UpdateCourse = lazy(() =>
  import("pages/CourseManagement/Courses/UpdateCourse")
);
const UpdateBillingCourse = lazy(() =>
  import("pages/CourseManagement/BillingCourse/UpdateBillingCourse")
);
// const WelcomeEmail = lazy(() =>
//   import("pages/CourseManagement/BillingCourse/WelcomeEmail")
// );
const WelcomeEmail = lazy(() =>
  import("pages/CourseManagement/BillingCourse/welcomeEmail/WelcomeEmail")
);
const DetailVideoMapping = lazy(() =>
  import("pages/CourseManagement/BillingCourse/Mapping/DetailVideoMapping")
);
const RecommendedCoursesMapping = lazy(() =>
  import(
    "pages/CourseManagement/BillingCourse/Mapping/RecommendedCoursesMapping"
  )
);
const DemoLectureMapping = lazy(() =>
  import("pages/CourseManagement/BillingCourse/Mapping/DemoLectureMapping")
);
const FaqMapping = lazy(() =>
  import("pages/CourseManagement/BillingCourse/Mapping/FaqMapping")
);
const CoreTakeAwayMapping = lazy(() =>
  import("pages/CourseManagement/BillingCourse/Mapping/CoreTakeAwayMapping")
);
const CoursePlatformMapping = lazy(() =>
  import("pages/CourseManagement/BillingCourse/Mapping/CoursePlatformMapping")
);
const ViewMappedSubject = lazy(() =>
  import("pages/CourseManagement/SubjectMapping/ViewMappedSubject")
);

const CourseMapping = lazy(() =>
  import("pages/CourseManagement/CourseMapping/CourseMapping")
);

const CourseMentors = lazy(() =>
  import("pages/CourseManagement/MentorsCourses/CourseMentors")
);

const AssignedMentors = lazy(() =>
  import("pages/CourseManagement/MentorsCourses/Sub/AssignedMentors")
);

const HumanitesCourses = lazy(() =>
  import("pages/CourseManagement/HumanitiesCourses/HumanitesCourses")
);
const AddHumanitiesCourses = lazy(() =>
  import("pages/CourseManagement/HumanitiesCourses/sub/AddHumanitiesCourses")
);
const EditHumanitiesCourses = lazy(() =>
  import("pages/CourseManagement/HumanitiesCourses/sub/EditHumanitiesCourses")
);



// **************************** Billing Management ************************************
const StudentAdmission = lazy(() =>
  import("pages/BillingManagement/StudentAdmission")
);
const NewStudentAdmission = lazy(() =>
  import("pages/BillingManagement/NewStudentAdmission")
);

const StudentDetails = lazy(() =>
  import("pages/BillingManagement/StudentDetails")
);
const GSTReport = lazy(() => import("pages/BillingManagement/GSTReport"));
const ViewBillingCourses = lazy(() =>
  import("pages/CourseManagement/BillingCourse/ViewBillingCourses")
);
const IOSBillingCourse = lazy(() =>
  import("pages/CourseManagement/BillingCourse/IOSBillingCourse")
);
const AddBillingCourse = lazy(() =>
  import("pages/CourseManagement/BillingCourse/AddBillingCourse")
);
const ViewCourseCategory = lazy(() =>
  import("pages/CourseManagement/CourseCategory/ViewCourseCategory")
);
const AddCourseCategory = lazy(() =>
  import("pages/CourseManagement/CourseCategory/AddCourseCategory")
);
const ViewSubCategories = lazy(() =>
  import("pages/CourseManagement/CourseSubCategory/ViewSubCategories")
);

const ViewSubCategoryPlatform = lazy(() =>
  import(
    "pages/CourseManagement/SubCategoryPlatformManagement/ViewSubCategoryPlatform"
  )
);

const ViewGPSCourses = lazy(() =>
  import("pages/CourseManagement/GPSEnabledCourses/ViewGPSCourses")
);
const AddGPSCourse = lazy(() =>
  import("pages/CourseManagement/GPSEnabledCourses/AddGPSCourse")
);
const ViewGPSAccessStudent = lazy(() =>
  import("pages/CourseManagement/GPSAccessStudent/ViewGPSAccessStudent")
);
const GPSAccessStudentList = lazy(() =>
  import("pages/CourseManagement/GPSAccessStudent/GPSAccessStudentList")
);
const UpdateGPSCourse = lazy(() =>
  import("pages/CourseManagement/GPSEnabledCourses/UpdateGPSCourse")
);
const SingleGPSCourse = lazy(() =>
  import("pages/CourseManagement/GPSEnabledCourses/SingleGPSCourse")
);

const CoursePlatform = lazy(() =>
  import("pages/CourseManagement/Courses/CoursePlatform")
);
const CoursesLectures = lazy(() =>
  import("pages/CourseManagement/Courses/CoursesLectures")
);
const ViewCourses = lazy(() =>
  import("pages/CourseManagement/Courses/ViewCourses")
);
const CourseSectionList = lazy(() =>
  import("pages/CourseManagement/Courses/CourseSection/CourseSectionList")
);
const CourseLectureList = lazy(() =>
  import("pages/CourseManagement/Courses/CourseLectures/CourseLectureList")
);
const SectionLectureList = lazy(() =>
  import("pages/CourseManagement/Courses/CourseSection/MapSectionLecture")
);
const ViewSubjects = lazy(() =>
  import("pages/SubjectManagement/Subject/ViewSubjects")
);

const EvaluationPricing = lazy(() =>
  import("pages/CourseManagement/EvaluationPricing/EvaluationPricing")
);
const MobileNumber = lazy(() =>
  import("pages/StudentManagement/Students/MobileVerification/VerifyMobile")
);

const EnrollStudentTab = lazy(() =>
  import("pages/EnrollTab/EnrollStudentTab")
);

// ----------------------- Student Management -----------------------//
const ViewStudents = lazy(() =>
  import("pages/StudentManagement/Students/ViewStudents")
);
const ViewTabCourseAccess = lazy(() =>
  import("pages/StudentManagement/WebsiteAccessToTab/ViewTabCourseAccess")
);
const AddTabCourseAccess = lazy(() =>
  import("pages/StudentManagement/WebsiteAccessToTab/AddTabCourseAccess")
);
const ViewLiveLectureAccess = lazy(() =>
  import("pages/StudentManagement/LiveLectureAccess/ViewLiveLectureAccess")
);
const AddLiveLectureAccess = lazy(() =>
  import("pages/StudentManagement/LiveLectureAccess/AddLiveLectureAccess")
);
const ViewTabLectureAccess = lazy(() =>
  import(
    "pages/StudentManagement/TabLectureDownloadAccess/ViewTabLectureAccess"
  )
);
const AddTabLectureAccess = lazy(() =>
  import("pages/StudentManagement/TabLectureDownloadAccess/AddTabLectureAccess")
);
const SingleStudent = lazy(() =>
  import("pages/StudentManagement/Students/SingleStudent")
);
const CourseWiseStudent = lazy(() =>
  import("pages/StudentManagement/CourseWiseStudent/CourseWiseStudent")
);

const CreateUserProfile = lazy(() =>
  import("pages/StudentManagement/CreateStudentProfile/UserProfile")
);

const UpdateUserProfile = lazy(() =>
  import("pages/StudentManagement/UpdateStudentProfile/UpdateStudentProfile")
);

const ViewStudentProfile = lazy(() =>
  import("pages/StudentManagement/UpdateStudentProfile/ViewProfile")
);

const SMStudentDetails = lazy(() =>
  import("pages/StudentManagement/StudentDetails/StudentDetails")
);



// ----------------------- Website Management -----------------------//
// const ViewCurrentAffairs = lazy(() =>
//   import("pages/WebsiteManagement/CurrentAffairs/ViewCurrentAffairs")
// );
// const AddCurrentAffairs = lazy(() =>
//   import("pages/WebsiteManagement/CurrentAffairs/AddCurrentAffairs")
// );
// const ViewBlogs = lazy(() => import("pages/WebsiteManagement/Blogs/ViewBlogs"));
// const AddBlog = lazy(() => import("pages/WebsiteManagement/Blogs/AddBlog"));
const ViewBanners = lazy(() =>
  import("pages/WebsiteManagement/Banner/ViewBanners")
);
const AddBanner = lazy(() =>
  import("pages/WebsiteManagement/Banner/AddBanner")
);
const UpdateBanner = lazy(() =>
  import("pages/WebsiteManagement/Banner/UpdateBanner")
);
const SingleBanner = lazy(() =>
  import("pages/WebsiteManagement/Banner/SingleBanner")
);
// const SingleCurrentAffairs = lazy(() =>
//   import("pages/WebsiteManagement/CurrentAffairs/SingleCurrentAffairs")
// );
// const UpdateCurrentAffairs = lazy(() =>
//   import("pages/WebsiteManagement/CurrentAffairs/UpdateCurrentAffairs")
// );
// const ViewBlogCategory = lazy(() =>
//   import("pages/WebsiteManagement/BlogCategory/ViewBlogCategory")
// );
const ViewStudentTestimonials = lazy(() =>
  import("pages/WebsiteManagement/StudentTestimonials/ViewStudentTestimonials")
);
const AddStudentTestimonial = lazy(() =>
  import("pages/WebsiteManagement/StudentTestimonials/AddStudentTestimonial")
);
const UpdateStudentTestimonial = lazy(() =>
  import("pages/WebsiteManagement/StudentTestimonials/UpdateStudentTestimonial")
);
const SingleStudentTestimonial = lazy(() =>
  import("pages/WebsiteManagement/StudentTestimonials/SingleStudentTestimonial")
);
// const SingleBlog = lazy(() =>
//   import("pages/WebsiteManagement/Blogs/SingleBlog")
// );
// const UpdateBlog = lazy(() =>
//   import("pages/WebsiteManagement/Blogs/UpdateBlog")
// );
const ViewOurPrograms = lazy(() =>
  import("pages/WebsiteManagement/OurPrograms/ViewOurPrograms")
);
const UpdateOurProgram = lazy(() =>
  import("pages/WebsiteManagement/OurPrograms/UpdateOurProgram")
);
const RegionalPrograms = lazy(() =>
  import("pages/WebsiteManagement/OurPrograms/RegionalPrograms")
);
const AddProgram = lazy(() =>
  import("pages/WebsiteManagement/OurPrograms/Regional/AddProgram")
);
const UpdateProgram = lazy(() =>
  import("pages/WebsiteManagement/OurPrograms/Regional/UpdateProgram")
);
const ViewToppers = lazy(() =>
  import("pages/WebsiteManagement/Toppers/ViewToppers")
);
const AddTopper = lazy(() =>
  import("pages/WebsiteManagement/Toppers/AddTopper")
);
const UpdateTopper = lazy(() =>
  import("pages/WebsiteManagement/Toppers/UpdateTopper")
);
const SingleTopper = lazy(() =>
  import("pages/WebsiteManagement/Toppers/SingleTopper")
);
const ViewWhyJoin = lazy(() =>
  import("pages/WebsiteManagement/WhyJoin/ViewWhyJoin")
);
const ViewTeacherDisplayOrder = lazy(() =>
  import("pages/WebsiteManagement/TeacherDisplayOrder/ViewTeacherDisplayOrder")
);
const AddWhyJoin = lazy(() =>
  import("pages/WebsiteManagement/WhyJoin/AddWhyJoin")
);
const UpdateWhyJoin = lazy(() =>
  import("pages/WebsiteManagement/WhyJoin/UpdateWhyJoin")
);
const SingleWhyJoin = lazy(() =>
  import("pages/WebsiteManagement/WhyJoin/SingleWhyJoin")
);

//******************************** Static URL Courses ********************************************* */
const StaticUrlCourses = lazy(() =>
  import("pages/WebsiteManagement/StaticUrlCourses/StaticUrlCourses")
);

const ViewSubSubjects = lazy(() =>
  import("pages/SubjectManagement/SubSubject/ViewSubSubject")
);

// ----------------------- Lecture Management -----------------------//
const ViewLectures = lazy(() => import("pages/LectureManagement/ViewLecture"));
const AddLecture = lazy(() => import("pages/LectureManagement/AddLecture"));
const UpdateLecture = lazy(() =>
  import("pages/LectureManagement/UpdateLecture")
);

// ----------------------- Staff Management -----------------------//
const AddStaff = lazy(() => import("pages/StaffManagement/AddStaff"));
const SingleStaff = lazy(() => import("pages/StaffManagement/SingleStaff"));
const UpdateStaff = lazy(() => import("pages/StaffManagement/UpdateStaff"));
const ViewStaffs = lazy(() => import("pages/StaffManagement/ViewStaffs"));

const IntroVideoMappingForStaff = lazy(() =>
  import("pages/StaffManagement/Mapping/IntroVideoMapping")
);
const RecommendedCoursesMappingForStaff = lazy(() =>
  import("pages/StaffManagement/Mapping/RecommendedCoursesMapping")
);
const DemoLectureMappingForStaff = lazy(() =>
  import("pages/StaffManagement/Mapping/DemoLectureMapping")
);

// ----------------------- Test Management -----------------------//
const AllTestListing = lazy(() =>
  import("pages/TestManagement/AllTest/AllTestListing")
);
const AddTest = lazy(() => import("pages/TestManagement/AllTest/AddTest"));
const UpdateTest = lazy(() =>
  import("pages/TestManagement/AllTest/UpdateTest")
);
const QuestionList = lazy(() =>
  import("pages/TestManagement/AllTest/QuestionSheet/QuestionList")
);

const AddQuestion = lazy(() =>
  import("pages/TestManagement/AllTest/QuestionSheet/AddQuestion")
);
const UpdateQuestion = lazy(() =>
  import("pages/TestManagement/AllTest/QuestionSheet/UpdateQuestion")
);
const TestBankListing = lazy(() =>
  import("pages/TestManagement/ArtifactBank/TestBankListing")
);
const MapTestToCourses = lazy(() =>
  import("pages/TestManagement/MapTestToCourses/MapTestToCourses")
);
const ArtifactMappingList = lazy(() =>
  import("pages/TestManagement/ArtifactMapping/ArtifactMappingList")
);

// ----------------------- Coupon Management -----------------------//
const ViewCoupons = lazy(() => import("pages/CouponManagement/ViewCoupons"));
const AddCoupon = lazy(() => import("pages/CouponManagement/AddCoupon"));
const UpdateCoupon = lazy(() => import("pages/CouponManagement/UpdateCoupon"));

// ----------------------- Master Bank -----------------------//
const ViewWebsiteVideoBank = lazy(() =>
  import("pages/MasterBank/WebsiteVideoBank/ViewVideoBank")
);
const AddWebsiteVideoBank = lazy(() =>
  import("pages/MasterBank/WebsiteVideoBank/AddVideoBank")
);
const UpdateWebsiteVideoBank = lazy(() =>
  import("pages/MasterBank/WebsiteVideoBank/UpdateVideoBank")
);

const ViewFaqs = lazy(() => import("pages/MasterBank/Faqs/ViewFaqs"));
const AddFaqs = lazy(() => import("pages/MasterBank/Faqs/AddFaqs"));
const UpdateFaqs = lazy(() => import("pages/MasterBank/Faqs/UpdateFaq"));

const ViewCoreTakeaway = lazy(() =>
  import("pages/MasterBank/CoreTakeAway/ViewCoreTakeaway")
);
const AddCoreTakeaway = lazy(() =>
  import("pages/MasterBank/CoreTakeAway/AddCoreTakeaway")
);
const UpdateCoreTakeaway = lazy(() =>
  import("pages/MasterBank/CoreTakeAway/UpdateCoreTakeaway")
);

// ----------------------- Help & Support -----------------------//
const ViewHelps = lazy(() => import("pages/Support/Help/ViewHelp"));
const ViewContact = lazy(() => import("pages/Support/Contact/ViewContact"));

// ----------------------- Live Lectures -----------------------//
const ViewLiveLectures = lazy(() =>
  import("pages/LiveLectures/ViewLiveLectures")
);
const AddLiveLecture = lazy(() => import("pages/LiveLectures/AddLiveLecture"));
const UpdateLiveLecture = lazy(() =>
  import("pages/LiveLectures/UpdateLiveLecture")
);

// ----------------------- Group Meetings -----------------------//
const ViewGroupMeetings = lazy(() =>
  import("pages/GroupMeetings/ViewGroupMeetings")
);
const AddGroupMeeting = lazy(() =>
  import("pages/GroupMeetings/AddGroupMeeting")
);
const UpdateGroupMeeting = lazy(() =>
  import("pages/GroupMeetings/UpdateGroupMeeting")
);

// ----------------------- Center Management -----------------------//
const ViewCenters = lazy(() => import("pages/CenterManagement/ViewCenters"));
const IntroVideoMapping = lazy(() =>
  import("pages/CenterManagement/mapping/IntroVideoMapping")
);
const BillingCoursesMapping = lazy(() =>
  import("pages/CenterManagement/mapping/BillingCoursesMapping")
);
const FaqMappingForCenter = lazy(() =>
  import("pages/CenterManagement/mapping/FaqMapping")
);

// ----------------------- Upcoming Courses -----------------------//
const UpcomingCourses = lazy(() =>
  import("pages/UpcomingCourses/UpcomingCourses")
);

// ----------------------- Daily Quiz -----------------------//
const ViewQuizs = lazy(() => import("pages/DailyQuiz/ViewQuizs"));
const AddQuiz = lazy(() => import("pages/DailyQuiz/AddQuiz"));
const UpdateQuiz = lazy(() => import("pages/DailyQuiz/UpdateQuiz"));

// ----------------------- Notification -----------------------//
const ViewNotifications = lazy(() =>
  import("pages/NotificationManagement/ViewNotifications")
);
const AddNotification = lazy(() =>
  import("pages/NotificationManagement/AddNotification")
);

// ----------------------- Evaluation Summary -----------------------//
const EvaluationSummary = lazy(() =>
  import("pages/Evaluation/EvaluationSummary/EvaluationSummary")
);
const ModeratorBestCopies = lazy(() =>
  import("pages/Evaluation/ModeratorBestCopies/ModeratorBestCopies")
);
const BestCopies = lazy(() => import("pages/Evaluation/BestCopies/BestCopies"));
const UploadAnswerSheet = lazy(() =>
  import("pages/Evaluation/UploadAnswerSheet/UploadAnswerSheet")
);
const EvaluatorDashboard = lazy(() =>
  import("pages/Evaluation/EvaluatorDashboard/EvaluatorDashboard")
);
const CoordinatorDashboard = lazy(() =>
  import("pages/Evaluation/CoordinatorDashboard/CoordinatorDashboard")
);
const EvaluatorBilling = lazy(() =>
  import("pages/Evaluation/EvaluatorBilling/EvaluatorBilling")
);

// ----------------------- Permissions -----------------------//
const Permissions = lazy(() => import("pages/Permissions/permission"));

const AddKnoxTabletStudents = lazy(() =>
  import("pages/Permissions/AddKnoxTabletStudents")
);
// ----------------------- Welcome Popup -----------------------//
const WelcomePopup = lazy(() =>
  import("pages/WebsiteManagement/WelcomePopup/WelcomePopup")
);

// ----------------------- Report management -----------------------//
const LoginReport = lazy(() => import("pages/Reports/LoginReport/LoginReport"));

// ************************************** Student With Default Courses *****************************************
const StudentWithDefaultCourses = lazy(() =>
  import("pages/Reports/StudentWithDefaultCourses/StudentWithDefaultCourseList")
);

// const LectureAttemptLog = lazy(() => import("pages/Reports/LectureReport/LectureAttemptLog"));
// const KnoxApiErrorLog = lazy(() => import("pages/Reports/KNOX Reports/KnoxApiErrorLog"));
const LectureReport = lazy(() =>
  import("pages/Reports/LectureReport/LectureReport")
);
const TestReport = lazy(() => import("pages/Reports/TestReport/TestReport"));
const ExceptionalLectureReport = lazy(() =>
  import("pages/Reports/ExceptionalLectureReport/ExceptionalLectureReport")
);
const MainsTestReport = lazy(() =>
  import("pages/Reports/MainsTestReport/MainsTestReport")
);
const AffiliateMacId = lazy(() =>
  import("pages/Reports/LectureReport/AffiliateMacId")
);
const GroupMeetingReport = lazy(() =>
  import("pages/Reports/GroupMeetingReport/GroupMeetingReport")
);
// const LectureDetailsHistory = lazy(() =>
//   import("pages/Reports/LectureDetailsHistory/LectureDetailsHistory")
// );
const LectureDetailsHistory = lazy(() =>
  import("pages/Reports/LectureDetails/LectureDetailsHistory")
);

const OldStudentList = lazy(() =>
  import("pages/StudentManagement/Students/OldStudentList")
);

const AddOldStudentDetail = lazy(() =>
  import("pages/StudentManagement/Students/AddOldStudentDetails")
);

const CourseAccessReport = lazy(() =>
  import("pages/Reports/CourseAccessReport/CourseAccessReport")
);

const ImportData = lazy(() => import("pages/ImportData/ImportData"));
const PSIRTestStudents = lazy(() =>
  import("pages/PSIRTestStudents/PSIRTestStudents")
);
const StudentCountByTest = lazy(() =>
  import("pages/StudentReports/StudentCountByTest")
);
const StudentAttendanceCountReport = lazy(() =>
  import("pages/StudentReports/StudentAttendanceCountReport")
);
const StudentDetailsReport = lazy(() =>
  import("pages/StudentReports/StudentDetailsReport")
);
const StudentAttendanceReport = lazy(() =>
  import("pages/StudentReports/StudentAttendanceReport")
);
const CourseDetailVisitReport  = lazy(() =>
  import("pages/Reports/WebsiteVisitReports/CourseDetails")
);
const CourseSubscriptionVisitReport = lazy(() =>
  import("pages/Reports/WebsiteVisitReports/CourseSubscriptionVisitReport")
);

// ************************************* KYC Mangement ********************************************

const StudentKYCDetails = lazy(() =>
  import("pages/KYCManagement/StudentKYCDetails")
);
const VerifiedKYCList = lazy(() =>
  import("pages/KYCManagement/VerifiedKYCList")
);
const KYCVerifiedByStudentList = lazy(() =>
  import("pages/KYCManagement/KYCVerifiedByStudentList")
);
const RequestedKYCList = lazy(() =>
  import("pages/KYCManagement/RequestedKYCList")
);
const StudentKYCAction = lazy(() =>
  import("pages/KYCManagement/StudentKYCAction")
);

// ******************************** SEO Management **************************************
const SEODetails = lazy(() => import("pages/SEOManagement/SEODetails"));
const AddSEODetails = lazy(() =>
  import("pages/SEOManagement/SEOControl/AddSEODetails")
);
const UpdateSEODetails = lazy(() =>
  import("pages/SEOManagement/SEOControl/UpdateSEODetails")
);

// ********************************  Prelims Management  **************************************
const FreeResourcePrelims = lazy(() =>
  import(
    "pages/FreeResourceManagement/PrelimsManagement/PrelimsSubject/PrelimSubjects/PrelimsSubject"
  )
);

const HotTopicPrelims = lazy(() =>
  import(
    "pages/FreeResourceManagement/PrelimsManagement/HotTopicPrelims/HotTopicPrelims"
  )
);
const AddHotTopic = lazy(() =>
  import(
    "pages/FreeResourceManagement/PrelimsManagement/HotTopicPrelims/sub/AddHotTopic"
  )
);
const EditHotTopic = lazy(() =>
  import(
    "pages/FreeResourceManagement/PrelimsManagement/HotTopicPrelims/sub/EditHotTopic"
  )
);

const PrelimsPYQ = lazy(() =>
  import("pages/FreeResourceManagement/PrelimsManagement/GSPYQ/PrelimsPYQ")
);

const AddPrelimsPYQ = lazy(() =>
  import(
    "pages/FreeResourceManagement/PrelimsManagement/GSPYQ/sub/AddPrelimsPYQ"
  )
);

const EditPrelimsPYQ = lazy(() =>
  import(
    "pages/FreeResourceManagement/PrelimsManagement/GSPYQ/sub/EditPrelimsPYQ"
  )
);

// ********************************  Mains Management  **************************************
const FreeResourceMains = lazy(() =>
  import("pages/FreeResourceManagement/MainsManagement/MainPYQ/MainPYQ")
);

const AddMainsPYQ = lazy(() =>
  import("pages/FreeResourceManagement/MainsManagement/MainPYQ/sub/AddMainsPYQ")
);

const EditMainsPYQ = lazy(() =>
  import("pages/FreeResourceManagement/MainsManagement/MainPYQ/sub/EditMainPYQ")
);

// const MainsSubject = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/MainsSubject/MainsSubject")
// );

// const GS1 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS1/GS1")
// );
// const AddGS1 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS1/sub/AddGS1")
// );
// const EditGS1 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS1/sub/EditGS1")
// );

// const GS2 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS2/GS2")
// );
// const AddGS2 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS2/sub/AddGS2")
// );
// const EditGS2 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS2/sub/EditGS2")
// );

// const GS3 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS3/GS3")
// );
// const AddGS3 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS3/sub/AddGS3")
// );
// const EditGS3 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS3/sub/EditGS3")
// );

// const GS4 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS4/GS4")
// );
// const AddGS4 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS4/sub/AddGS4")
// );
// const EditGS4 = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/GS4/sub/EditGS4")
// );

// const HotTopicsMains = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/HotTopicsMains/HotTopicsMains")
// );
// const AddHotTopicsMains = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/HotTopicsMains/sub/AddHotTopicsMains")
// );
// const EditHotTopicsMains = lazy(() =>
//   import("pages/FreeResourceManagement/MainsManagement/HotTopicsMains/sub/EditHotTopicsMains")
// );

// ********************************  Optional Management  **************************************
// const OptionalSubjects = lazy(() =>
//   import("pages/FreeResourceManagement/OptionalManagement/OptionalSubject/OptionalSubjects")
// );

// const OptionalPYQ = lazy(() =>
//   import("pages/FreeResourceManagement/OptionalManagement/OptionalPYQ/OptionalPYQ")
// );
// const AddOptionalPYQ = lazy(() =>
//   import("pages/FreeResourceManagement/OptionalManagement/OptionalPYQ/sub/AddOptionalPYQ")
// );
// const EditOptionalPYQ = lazy(() =>
//   import("pages/FreeResourceManagement/OptionalManagement/OptionalPYQ/sub/EditOptionalPYQ")
// );

// const OptionalUpdates = lazy(() =>
//   import("pages/FreeResourceManagement/OptionalManagement/OptionalUpdates/OptionalUpdates")
// );
// const AddOptionalUpdates = lazy(() =>
//   import("pages/FreeResourceManagement/OptionalManagement/OptionalUpdates/sub/AddOptionalUpdates")
// );
// const EditOptionalUpdates = lazy(() =>
//   import("pages/FreeResourceManagement/OptionalManagement/OptionalUpdates/sub/EditOptionalUpdates")
// );

// ********************************  Interview Management  **************************************
// const FreeResourceInterview = lazy(() =>
//   import("pages/FreeResourceManagement/InterviewManagement/FreeResourceInterview")
// );

//************************************ Mentor Slot Management ********************************
//************************************ All Mentors ********************************
const MentorList = lazy(() =>
  import("pages/MentorSlotManagement/AllMentors/MentorList")
);

const MentorSlotList = lazy(() =>
  import("pages/MentorSlotManagement/AllMentors/sub/AdminMentorSlotManagement")
);

const MentorSlotListbyMentorID = lazy(() =>
  import(
    "pages/MentorSlotManagement/AllMentors/sub/AdminMentorSlotManagementbyMentorID"
  )
);

//************************************ Minutes of Meeting Questions ********************************
const MinutesOfMeetingQuestionsList = lazy(() =>
  import(
    "pages/MentorSlotManagement/MinutesOfMeeting/MinutesOfMeetingQuestionsList"
  )
);

//************************************ My Slots ********************************
const MySlots = lazy(() =>
  import("pages/MentorSlotManagement/MySlots/MySlots")
);

//************************************ My Appointments ********************************
const MyAppointmentList = lazy(() =>
  import("pages/MentorSlotManagement/MyAppointment/MyAppointmentList")
);

const EditStudentDetails = lazy(() =>
  import("pages/MentorSlotManagement/MyAppointment/sub/EditStudentDetails")
);

const ViewMeetingDetails = lazy(() =>
  import("pages/MentorSlotManagement/MyAppointment/sub/ViewMeetingDetails")
);

//************************************ Upcomming Appointments ********************************
const UpAppointmentList = lazy(() =>
  import("pages/MentorSlotManagement/UpcommingAppointment/UpAppointmentList")
);

//************************************ Exceptional Lecture Management ********************************
const ExceptionalLectures = lazy(() =>
  import("pages/ExceptionalLectureManagement/ExceptionalLectures")
);

//************************************ Ip Access Management ********************************
const IpAccessList = lazy(() =>
  import("pages/IpAccessManagement/IpAccessList/IpAccessList")
);
const IpAccessLog = lazy(() =>
  import("pages/IpAccessManagement/IpAccessLog/IpAccessLog")
);



export const sidebar = (roles = []) => {
  const sidebarData = [
    {
      id: 100000,
      enabled: roles.includes(100000),
      name: "Dashboard",
      icon: <DashboardIcon fontSize="small" />,
      path: "/dashboard",
      isChild: null,
    },
    {
      id: 110000,
      enabled: roles.includes(110000),
      name: "Manage Staff",
      icon: <Groups3Icon fontSize="small" />,
      path: "/staff-management",
      isChild: null,
    },
    {
      id: 120000,
      enabled: roles.includes(120000),
      name: "Course Management",
      icon: <MenuBookIcon fontSize="small" />,
      path: "/course-management",
      isChild: [
        {
          id: 120100,
          enabled: roles.includes(120100),
          name: "Billing Courses",
          path: "/course-management/billing-courses",
        },
        {
          id: 2563511,
          enabled: roles.includes(2563511),
          name: "IOS Billing Courses",
          path: "/course-management/ios-billing-courses",
        },
        {
          id: 120200,
          enabled: roles.includes(120200),
          name: "All Courses",
          path: "/course-management/courses",
        },
        {
          id: 652478,
          enabled: roles.includes(652478),
          name: "Mentors Course",
          path: "/course-management/mentors-course",
        },
        {
          id: 652478,
          enabled: roles.includes(120200),
          name: "Humanity Courses",
          path: "/course-management/humanity-courses",
        },
        {
          id: 120300,
          enabled: roles.includes(120300),
          name: "Course Category",
          path: "/course-management/course-category",
        },
        {
          id: 120400,
          enabled: roles.includes(120400),
          name: "Course Sub-Category",
          path: "/course-management/course-sub-category",
        },

        {
          id: 120400,
          // enabled: false,
          enabled: roles.includes(120400),
          name: "Sub-Category Platform",
          path: "/course-management/sub-category-platform",
        },

        {
          id: 120500,
          enabled: roles.includes(120500),
          name: "GPS Enable to courses",
          path: "/course-management/gps-enable-course",
        },
        {
          id: 120600,
          enabled: roles.includes(120600),
          name: "GPS Access to Student",
          path: "/course-management/gps-access-student",
        },
        {
          id: 120700,
          enabled: roles.includes(120700),
          name: "Map Subject To Course",
          path: "/course-management/map-course",
        },
        {
          id: 120800,
          enabled: roles.includes(120800),
          name: "assign courses to affiliates",
          path: "/course-management/course-mapping",
        },
        // {
        //   id: 120800,
        //   // enabled: roles.includes(120800),
        //   enabled: false,
        //   name: "Evaluation Pricing",
        //   path: "/course-management/evaluation-pricing",
        // },
        {
          id: 120800,
          // enabled: roles.includes(120800),
          enabled: false,
          name: "Evaluation Pricing",
          path: "/course-management/evaluation-pricing",
        },
        {
          id: 120811,
          enabled: roles.includes(120811),
          name: "Courses Platform",
          path: "/course-management/course-platform",
        },
        {
          id: 120812,
          enabled: roles.includes(120812),
          name: "Courses Lectures",
          path: "/course-management/courses",
        },
      ],
    },
    {
      id: 130000,
      enabled: roles.includes(130000),
      name: "Student Management",
      icon: <SchoolIcon fontSize="small" />,
      path: "/student-management",
      isChild: [
        {
          id: 130100,
          enabled: roles.includes(130100),
          name: "All Student List",
          path: "/student-management/all-students",
        },
        {
          id: 120116,
          enabled: roles.includes(120116),
          name: "Student Details",
          path: "/student-management/student-details",
        },
        {
          id: 564812,
          enabled: roles.includes(564812),
          name: "GPS Courses Student List",
          path: "/student-management/gps-courses-student-list",
        },
        {
          id: 130200,
          enabled: roles.includes(130200),
          name: "Course Wise Student",
          path: "/student-management/course-wise-student",
        },
        {
          id: 130300,
          // enabled: roles.includes(130300),
          enabled: false,
          name: "Website Access to tab Course",
          path: "/student-management/tab-course-access",
        },
        {
          id: 130400,
          // enabled: roles.includes(130400),
          enabled: false,
          name: "Live Lecture Access on website",
          path: "/student-management/live-lecture-access",
        },
        {
          id: 130500,
          enabled: roles.includes(130500),
          name: "TAB-Lecture Download Access",
          path: "/student-management/tab-lecture-download-access",
        },
        {
          id: 652480,
          enabled: roles.includes(652480),
          name: "Create student profile",
          path: "/student-management/create-student-profile",
        },
        {
          id: 130600,
          enabled: roles.includes(130600),
          name: "Update student profile",
          path: "/student-management/update-profile",
        },
        {
          id: 9658545,
          enabled: roles.includes(9658545),
          name: "Old Student List",
          path: "/old-student-list",
        },
        {
          id: 5124895,
          enabled: roles.includes(5124895),
          name: "Student Watching Hours",
          path: "/student-watching-hours",
        },
        {
          id: 5124896,
          enabled: roles.includes(5124896),
          name: "Student Course Test Report",
          path: "/student-course-test-report",
        },
        {
          id: 9658546,
          enabled: false,
          name: "Verify Mobile Number",
          path: "/verify-mobile",
        },
        {
          id: 9658547,
          enabled: roles.includes(9658547),
          name: "Enroll Student's Tab",
          path: "/enroll-student-tab",
        },
        // {
        //   id: 130600,
        //   enabled: roles.includes(130600),
        //   name: "Student profile",
        //   path: "/student-management/view-profile",
        // },
      ],
    },

    {
      id: 140000,
      enabled: roles.includes(140000),
      name: "Test Bank Management",
      icon: <HistoryEduIcon fontSize="small" />,
      path: "/test-management",
      isChild: [
        {
          id: 140100,
          enabled: roles.includes(140100),
          name: "All Test",
          path: "/test-management/test",
        },
        {
          id: 140200,
          enabled: roles.includes(140200),
          name: "Artifacts Bank",
          path: "/test-management/test-bank",
        },
        {
          id: 140300,
          enabled: roles.includes(140300),
          name: "Artifacts Mapping",
          path: "/test-management/artifacts-mapping",
        },
        {
          id: 140400,
          enabled: roles.includes(140400),
          name: "Map Tests to Courses",
          path: "/test-management/map-test-to-courses",
        },
      ],
    },
    {
      id: 310000,
      enabled: roles.includes(310000),
      name: "Evaluation",
      icon: <PlaylistAddCheckCircleIcon fontSize="small" />,
      path: "/evaluation-management",
      isChild: [
        {
          id: 310100,
          enabled: roles.includes(310100),
          name: "Evaluation Summary",
          path: "/evaluation-management/evaluation-summary",
        },
        {
          id: 310200,
          enabled: roles.includes(310200),
          name: "Moderator Best Copies",
          path: "/evaluation-management/moderator-best-copies",
        },
        {
          id: 310300,
          enabled: roles.includes(310300),
          name: "Best Copies",
          path: "/evaluation-management/best-copies",
        },
        {
          id: 310400,
          enabled: roles.includes(310400),
          name: "Upload Answer Sheet",
          path: "/evaluation-management/upload-answer-sheet",
        },
        {
          id: 310500,
          enabled: roles.includes(310500),
          name: "Evaluator Dashboard",
          path: "/evaluation-management/evaluator-dashboard",
        },
        {
          id: 310500,
          enabled: roles.includes(310500),
          name: "Coordinator Dashboard",
          path: "/evaluation-management/coordinator-dashboard",
        },
        {
          id: 310600,
          enabled: roles.includes(310600),
          name: "Evaluator Billing",
          path: "/evaluation-management/evaluator-billing",
        },
      ],
    },

    // ****************************** Exceptional Lecture Management *************************

    // ****************************** Website Management *************************
    {
      id: 150000,
      enabled: roles.includes(150000),
      name: "Website Management",
      icon: <LanguageIcon fontSize="small" />,
      path: "/website-management",
      isChild: [
        {
          id: 150100,
          enabled: roles.includes(150100),
          name: "Banner",
          path: "/website-management/banners",
        },
        {
          id: 150300,
          enabled: roles.includes(150300),
          name: "Student Testimonials",
          path: "/website-management/student-testimonials",
        },
        {
          id: 150550,
          enabled: roles.includes(150550),
          name: "Student Exceptional Lecture Management",
          path: "/exceptional-lecture-management",
        },
        {
          id: 150700,
          enabled: roles.includes(150700),
          name: "Our Programs",
          path: "/website-management/our-programs",
        },
        {
          id: 150800,
          enabled: roles.includes(150800),
          name: "Institute Toppers",
          path: "/website-management/institute-toppers",
        },
        {
          id: 150900,
          enabled: roles.includes(150900),
          name: "Why Join Us",
          path: "/website-management/why-join-us",
        },
        {
          id: 130300,
          enabled: roles.includes(130300),
          name: "Teacher Display Order",
          path: "/website-management/teacher-display-order",
        },
        {
          id: 161005,
          enabled: roles.includes(161005),
          name: "Upcoming Exam and Course Display Order",
          path: "/website-management/upcoming-exam-and-course-display-order",
        },
        {
          id: 151000,
          enabled: roles.includes(151000),
          name: "Advertisement",
          path: "/website-management/welcome-popup",
        },
        {
          id: 161000,
          enabled: roles.includes(161000),
          name: "Site Setting",
          path: "/website-management/site-setting",
        },
        {
          id: 161001,
          enabled: roles.includes(161001),
          name: "Static URL Courses",
          path: "/website-management/static-url-courses",
        },
      ],
    },

    // ****************************** SEO Management *************************
    {
      id: 70000,
      enabled: roles.includes(70000),
      name: "Mentor's Slot Management",
      icon: <EventIcon fontSize="small" />,
      path: "/mentor-slot-management",     
      isChild: [
          {
            id: 70001,
            enabled: roles.includes(70001),
            name: "All Mentor's Slot",
            path: "/mentor-slot-management/all-mentors",
          },
          {
            id: 70002,
            enabled: roles.includes(70002),
            name: "My Slot's",
            path: "/mentor-slot-management/my-slots",
          },
          {
            id: 70003,
            enabled: roles.includes(70003),
            name: (localStorage.getItem("roleid") == 12 && "My Appointments") || "Mentor Appointments",
            path: "/mentor-slot-management/my-appointments",
          },
          {
            id: 70004,
            enabled: roles.includes(70004),
            name: "Upcoming Appointments",
            path: "/mentor-slot-management/Upcoming-appointment",
          },
      ],
    
    
    },
    // ****************************** SEO Management *************************
    {
      id: 350000,
      enabled: roles.includes(350000),
      name: "SEO Management",
      icon: <SearchIcon fontSize="small" />,
      path: "/seo-details",
      isChild: null,
    },

    //
    // ****************************** Prelims Management *************************
    {
      id: 600000,
      enabled: roles.includes(600000),
      name: "Prelims Management",
      icon: <ManageAccountsIcon fontSize="small" />,
      path: "/prelims",
      isChild: [
        // {
        //   id: 600001,
        //   enabled: roles.includes(600001),
        //   name: "Prelims Syllabus",
        //   path: "/prelims/prelims-syllabus",
        // },
        {
          id: 600001,
          enabled: roles.includes(600001),
          name: "Prelims PYQ",
          path: "/prelims/gs-pyq",
        },
        // {
        //   id: 160100,
        //   enabled: roles.includes(150000),
        //   name: "Hot Topic for Prelims 2024",
        //   path: "/prelims/hot-topic-prelims",
        // },
        // {
        //   id: 160100,
        //   enabled: roles.includes(150000),
        //   name: "Prelims Subject",
        //   path: "/prelims/prelims-subject",
        // },
      ],
    },

    // ****************************** Mains Management *************************
    // {
    //   id: 150000,
    //   enabled: roles.includes(150000),
    //   name: "Mains Management",
    //   icon: <AssignmentIcon fontSize="small" />,
    //   path: "/mains",
    //   isChild: [
    //     {
    //       id: 160100,
    //       enabled: roles.includes(150000),
    //       name: "Mains PYQ",
    //       path: "/mains/mains-pyq",
    //     },
    // //     {
    // //       id: 160100,
    // //       enabled: roles.includes(150000),
    // //       name: "Mains Subject",
    // //       path: "/mains/mains-subject",
    // //     },
    // //     {
    // //       id: 160100,
    // //       enabled: roles.includes(150000),
    // //       name: "GS-1",
    // //       path: "/mains/gs-1",
    // //     },
    // //     {
    // //       id: 160100,
    // //       enabled: roles.includes(150000),
    // //       name: "GS-2",
    // //       path: "/mains/gs-2",
    // //     },
    // //     {
    // //       id: 160100,
    // //       enabled: roles.includes(150000),
    // //       name: "GS-3",
    // //       path: "/mains/gs-3",
    // //     },
    // //     {
    // //       id: 160100,
    // //       enabled: roles.includes(150000),
    // //       name: "GS-4",
    // //       path: "/mains/gs-4",
    // //     },
    // //     {
    // //       id: 160100,
    // //       enabled: roles.includes(150000),
    // //       name: "Hot Topics For Mains 2024",
    // //       path: "/mains/hot-topic-mains",
    //     // },
    //   ]
    // },

    // ****************************** Optional Management *************************
    // {
    //   id: 150000,
    //   enabled: roles.includes(150000),
    //   name: "Optional Management",
    //   icon: <CategoryIcon fontSize="small" />,
    //   path: "/optional",
    //   isChild: [
    //     {
    //       id: 160100,
    //       enabled: roles.includes(150000),
    //       name: "Optional Subjects",
    //       path: "/optional/optional-subject",
    //     },
    //     {
    //       id: 160100,
    //       enabled: roles.includes(150000),
    //       name: "Optional PYQ",
    //       path: "/optional/optional-pyq",
    //     },
    //     {
    //       id: 160100,
    //       enabled: roles.includes(150000),
    //       name: "Optional Updates",
    //       path: "/optional/optional-updates",
    //     },
    //   ]
    // },

    // ****************************** Interview Management *************************
    // {
    //   id: 150000,
    //   enabled: roles.includes(150000),
    //   name: "Interview Management",
    //   icon: <QuestionAnswerIcon fontSize="small" />,
    //   path: "/interview",
    //   isChild: [
    //     {
    //       id: 160100,
    //       enabled: roles.includes(150000),
    //       // name: "Prelims Subject",
    //       // path: "/prelims/prelims-subject",
    //     },
    //   ]
    // },

    {
      id: 160000,
      enabled: roles.includes(160000),
      name: "Master Bank",
      icon: <AccountBalanceIcon fontSize="small" />,
      path: "/bank-management",
      isChild: [
        {
          id: 160100,
          enabled: roles.includes(160100),
          name: "Faqs",
          path: "/bank-management/faqs",
        },
        {
          id: 160200,
          enabled: roles.includes(160200),
          name: "Core Takeaway",
          path: "/bank-management/core-takeaway",
        },
        {
          id: 160300,
          enabled: roles.includes(160300),
          name: "Website Video Bank",
          path: "/bank-management/website-video-bank",
        },
      ],
    },
    {
      id: 170000,
      enabled: roles.includes(170000),
      name: "Centre Management",
      icon: <HubIcon fontSize="small" />,
      path: "/center-management",
      isChild: null,
    },
    {
      id: 180000,
      enabled: roles.includes(180000),
      name: "Upcoming Courses",
      icon: <UpcomingIcon fontSize="small" />,
      path: "/upcoming-courses",
      isChild: null,
    },
    {
      id: 190000,
      enabled: roles.includes(190000),
      name: "Daily Quiz",
      icon: <QuizIcon fontSize="small" />,
      path: "/daily-quiz",
      isChild: null,
    },
    {
      id: 200000,
      enabled: roles.includes(200000),
      name: "Lectures Management",
      icon: <FilterFramesIcon fontSize="small" />,
      path: "/lectures-management",
      isChild: null,
    },
    {
      id: 210000,
      enabled: roles.includes(210000),
      name: "Live Lectures",
      icon: <CastForEducationIcon fontSize="small" />,
      path: "/live-lectures",
      isChild: null,
    },
    {
      id: 220000,
      enabled: roles.includes(220000),
      name: "Group Meetings",
      icon: <Groups3Icon fontSize="small" />,
      path: "/group-meetings",
      isChild: null,
    },
    {
      id: 230000,
      enabled: roles.includes(230000),
      name: "Coupon Management",
      icon: <DiscountIcon fontSize="small" />,
      path: "/coupons-management",
      isChild: null,
    },
    {
      id: 240000,
      enabled: roles.includes(240000),
      name: "Subject Management",
      icon: <SubjectIcon fontSize="small" />,
      path: "/subject-management",
      isChild: [
        {
          id: 240100,
          enabled: roles.includes(240100),
          name: "Subject",
          path: "/subject-management/subject",
        },
        {
          id: 240200,
          enabled: roles.includes(240200),
          name: "Sub Subject",
          path: "/subject-management/sub-subject",
        },
      ],
    },

    //////////////////////////////////////////
    {
      id: 120009,
      enabled: roles.includes(120009),
      name: "Billing",
      icon: <MenuBookIcon fontSize="small" />,
      path: "/billing",
      isChild: [
        // {
        //   id: 120100,
        //   enabled: roles.includes(120100),
        //   name: "Student Admissions",
        //   path: "/billing/old-student-admissions",
        // },
        {
          id: 120100,
          enabled: roles.includes(120100),
          name: "Student Admissions",
          path: "/billing/student-admissions",
        },
        {
          id: 120100,
          enabled: roles.includes(120100),
          name: "GST Report",
          path: "/billing/gst-report",
        },
        {
          id: 120111,
          enabled: roles.includes(120111),
          name: "Student Details",
          path: "/billing/student-details",
        },
        {
          id: 120112,
          enabled: roles.includes(120112),
          name: "Affiliate Billing",
          path: "/billing/affiliate-billing-details",
        },
      ],
    },
    {
      id: 666666,
      enabled: roles.includes(666666),
      name: "Student Test Reports",
      icon: <SummarizeIcon fontSize="small" />,
      path: "/student-test-report",
      isChild: [
        // {
        //   id: 120100,
        //   enabled: roles.includes(120100),
        //   name: "Student Admissions",
        //   path: "/billing/old-student-admissions",
        // },
        {
          id: 666661,
          enabled: roles.includes(666661),
          name: "Import Data",
          path: "/student-test-report/import-data",
        },
        {
          id: 666662,
          enabled: roles.includes(666662),
          name: "PSIR Test Students",
          path: "/student-test-report/psir-test-students",
        },
        {
          id: 666663,
          enabled: roles.includes(666663),
          name: "Students Count By Test, Average marks & Percentage",
          path: "/student-test-report/students-count",
        },
        {
          id: 666664,
          enabled: roles.includes(666664),
          name: "Student Attendance Count Report",
          path: "/student-test-report/students-attendance-count",
        },
        {
          id: 666665,
          enabled: roles.includes(666665),
          name: "Student Details Report",
          path: "/student-test-report/students-details-report",
        },
        {
          id: 666667,
          enabled: roles.includes(666667),
          name: "Student Attendance Report",
          path: "/student-test-report/students-attendance-report",
        },
      ],
    },

    ///////////////////////////////////////

    {
      id: 250000,
      enabled: roles.includes(250000),
      name: "Support",
      icon: <HelpIcon fontSize="small" />,
      path: "/support-management",
      isChild: [
        {
          id: 250100,
          enabled: roles.includes(250100),
          name: "Helps",
          path: "/support-management/helps",
        },
        {
          id: 250200,
          enabled: roles.includes(250200),
          name: "Contact us",
          path: "/support-management/contact",
        },
      ],
    },
    {
      id: 260000,
      enabled: roles.includes(260000),
      name: "Notifications",
      icon: <NotificationsNoneIcon fontSize="small" />,
      path: "/notifications",
      isChild: null,
    },
    {
      id: 320000,
      // enabled: roles.includes(320000),
      enabled: false,
      name: "Permissions",
      icon: <SettingsAccessibilityIcon fontSize="small" />,
      path: "/permissions",
      isChild: null,
    },
    {
      id: 320000,
      // enabled: roles.includes(320000),
      enabled: false,
      name: "AddKnoxTabletStudents",
      icon: <SettingsAccessibilityIcon fontSize="small" />,
      path: "/add-knox-exception",
      isChild: null,
    },
    {
      id: 890003,
      enabled: roles.includes(890003),
      name: "Affiliate MacID's",
      icon: <HistoryEduIcon fontSize="small" />,
      path: "/affiliate-macids",
    },
    {
      id: 890000,
      enabled: roles.includes(890000),
      name: "Reports",
      icon: <AssessmentIcon fontSize="small" />,
      path: "/reports-management",
      isChild: [
        {
          id: 330100,
          enabled: roles.includes(330100),
          name: "Login Report",
          path: "/reports-management/login",
        },
        {
          id: 652486,
          enabled: roles.includes(652486),
          name: "Sign Up Student",
          path: "/reports-management/student-default-courses",
        },
        {
          id: 225566,
          enabled: roles.includes(225566),
          name: "Admission Summary Report",
          path: "/reports-management/admission-summary-report",
        },
        {
          id: 9658500,
          enabled: roles.includes(9658500),
          name: "Admin Activity Report",
          path: "/course-access-reports",
        },
        {
          id: 890001,
          enabled: roles.includes(890001),
          name: "Knox Api Error Log ",
          path: "/reports-management/knox-api-error-log",
        },
        {
          id: 890002,
          enabled: roles.includes(890002),
          name: "Lecture Attempt Log",
          path: "/reports-management/lecture-attempt-log",
        },
        {
          id: 890004,
          enabled: roles.includes(890004),
          name: "Piracy Attempt Report",
          path: "/reports-management/piracy-attempt-report",
        },
        {
          id: 332211,
          enabled: roles.includes(332211),
          name: "Affiliate Student Admission Course Access Report",
          path: "/reports-management/aff-stud-admm-course-access-report",
        },

        {
          id: 330200,
          enabled: roles.includes(330200),
          name: "Lecture Report",
          path: "/reports-management/lecture",
        },
        {
          id: 330300,
          enabled: roles.includes(330300),
          name: "Prelims Test Report",
          path: "/reports-management/prelims-test",
        },
        {
          id: 330400,
          enabled: roles.includes(330400),
          name: "Mains Test Report",
          path: "/reports-management/mains-test",
        },
        {
          id: 330500,
          enabled: roles.includes(330500),
          name: "Exceptional Lecture Report",
          path: "/reports-management/exceptional-lecture",
        },
        {
          id: 330600,
          enabled: roles.includes(330600),
          name: "Group Meeting Report",
          path: "/reports-management/group-meeting",
        },
        {
          id: 330700,
          enabled: roles.includes(330700),
          name: "Lecture View Location Report",
          path: "/reports-management/lecture-details-history",
        },
        {
          id: 330800,
          enabled: roles.includes(330800),
          name: "LiveLecture View Location Report",
          path: "/reports-management/live-lecture-details-history",
        },
        {
          id: 100000,
          enabled: roles.includes(100000),
          name: "Course Detail Visit Report",
          path: "/reports-management/course-detail-visit-report",
        },
        {
          id: 100000,
          enabled: roles.includes(100000),
          name: "Course Subscription Visit Report",
          path:"/reports-management/course-subscription-visit-report",
        },
      ],
    },
    {
      id: 652471,
      enabled: roles.includes(652471),
      name: "KYC Management",
      icon: <AssessmentIcon fontSize="small" />,
      path: "/kyc-management",
      isChild: [
        {
          id: 652472,
          enabled: roles.includes(652472),
          name: "Student KYC Details",
          path: "/kyc-management/student-kyc-details",
        },
        {
          id: 652473,
          enabled: roles.includes(652473),
          name: "Requested KYC List",
          path: "/kyc-management/requested-kyc-list",
        },
        {
          id: 652475,
          enabled: roles.includes(652475),
          name: "Verified By Users",
          path: "/kyc-management/kyc-verified-student",
        },

        {
          id: 652474,
          enabled: roles.includes(652474),
          name: "Verified KYC List",
          path: "/kyc-management/verified-kyc-list",
        },
      ],
    },
    {
      id: 996611,
      enabled: roles.includes(996611),
      name: "PG Management",
      icon: <ManageAccountsIcon fontSize="small" />,
      path: "/pg-management",
      isChild: [
        {
          id: 996612,
          enabled: roles.includes(996612),
          name: "PG Master",
          path: "/pg-management/pg-master",
        },
        {
          id: 996616,
          enabled: roles.includes(996616),
          name: "Affilliate PG",
          path: "/pg-management/affiliate-pg",
        },
      ],
    },
    {
      id: 270000,
      enabled: false,
      name: "Course Admin",
      icon: <AdminPanelSettingsIcon fontSize="small" />,
      path: "/course-admin",
      isChild: null,
    },
    {
      id: 280000,
      enabled: false,
      name: "Mentors",
      icon: <SupervisedUserCircleIcon fontSize="small" />,
      path: "/mentors",
      isChild: null,
    },
    {
      id: 290000,
      enabled: false,
      name: "Counsellor",
      icon: <SupervisedUserCircleIcon fontSize="small" />,
      path: "/counsellor",
      isChild: null,
    },
    {
      id: 300000,
      enabled: false,
      name: "Topper",
      icon: <WorkspacePremiumIcon fontSize="small" />,
      path: "/topper",
      isChild: null,
    },
    {
      id: 888888,
      enabled: roles.includes(888888),
      name: "IP Access Management",
      icon: <IPAccessIcon fontSize="small" />,
      path: "/ip-access-management",
      isChild: [
        {
          id: 888881,
          enabled: roles.includes(888881),
          name: "IP Access List",
          path: "/ip-access-management/access-list",
        },
        {
          id: 888882,
          enabled: roles.includes(888882),
          name: "IP Access Log",
          path: "/ip-access-management/access-log",
        },
      ],
    },
  ].filter((side) => side.enabled);

  return sidebarData;
};

export const routes = (roles = []) => {
  const routesData = [
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <Dashboard />,
      path: "/dashboard",
    },
    {
      id: 110001,
      enabled: roles.includes(110001),
      element: <AddStaff />,
      path: "/staff-management/add",
    },
    {
      id: 110002,
      enabled: roles.includes(110002),
      element: <UpdateStaff />,
      path: "/staff-management/update/:id",
    },
    {
      id: 110003,
      enabled: roles.includes(110003),
      element: <SingleStaff />,
      path: "/staff-management/view/:id",
    },
    {
      id: 110000,
      enabled: roles.includes(110000),
      element: <ViewStaffs />,
      path: "/staff-management",
    },
    {
      id: 110006,
      enabled: roles.includes(110006),
      element: <IntroVideoMappingForStaff />,
      path: "/staff-management/:type/link-intro-video/:staffId",
    },
    {
      id: 110007,
      enabled: roles.includes(110007),
      element: <RecommendedCoursesMappingForStaff />,
      path: "/staff-management/:type/recommended-course-mapping/:staffId",
    },
    {
      id: 110008,
      enabled: roles.includes(110008),
      element: <DemoLectureMappingForStaff />,
      path: "/staff-management/:type/demo-lecture-mapping/:staffId",
    },
    {
      id: 120101,
      enabled: roles.includes(120101),
      element: <AddBillingCourse />,
      path: "/course-management/billing-courses/add",
    },
    {
      id: 120102,
      enabled: roles.includes(120102),
      element: <UpdateBillingCourse />,
      path: "/course-management/billing-courses/update/:id",
    },
    // ******************************** Welcome Email ****************************************
    {
      id: 120115,
      enabled: roles.includes(120115),
      element: <WelcomeEmail />,
      path: "/course-management/billing-courses/welcome-email/:id",
    },
    {
      id: 120103,
      enabled: roles.includes(120103),
      element: <SingleBillingCourse />,
      path: "/course-management/billing-courses/view/:id",
    },
    {
      id: 120100,
      enabled: roles.includes(120100),
      element: <ViewBillingCourses />,
      path: "/course-management/billing-courses",
    },
    {
      id: 2563511,
      enabled: roles.includes(2563511),
      element: <IOSBillingCourse />,
      path: "/course-management/ios-billing-courses",
    },
    {
      id: 120106,
      enabled: roles.includes(120106),
      element: <DetailVideoMapping />,
      path: "/course-management/billing-courses/link-course-video/:billingId",
    },
    {
      id: 120107,
      enabled: roles.includes(120107),
      element: <RecommendedCoursesMapping />,
      path: "/course-management/billing-courses/recommended-course-mapping/:billingId",
    },
    {
      id: 120108,
      enabled: roles.includes(120108),
      element: <DemoLectureMapping />,
      path: "/course-management/billing-courses/demo-lecture-mapping/:billingId",
    },
    {
      id: 120109,
      enabled: roles.includes(120109),
      element: <FaqMapping />,
      path: "/course-management/billing-courses/faq-mapping/:billingId",
    },
    {
      id: 120110,
      enabled: roles.includes(120110),
      element: <CoreTakeAwayMapping />,
      path: "/course-management/billing-courses/core-takeaway-mapping/:billingId",
    },
    {
      id: 120111,
      enabled: roles.includes(120111),
      element: <CoursePlatformMapping />,
      path: "/course-management/billing-courses/course-platform-detail-mapping/:billingId",
    },
    {
      id: 120700,
      enabled: roles.includes(120700),
      element: <ViewMappedSubject />,
      path: "/course-management/map-course",
    },
    {
      id: 120800,
      enabled: roles.includes(120800),
      element: <CourseMapping />,
      path: "/course-management/course-mapping",
    },

    // {
    //   id: 120800,
    //   // enabled: roles.includes(120800),
    //   enabled: false,
    //   element: <EvaluationPricing />,
    //   path: "/course-management/evaluation-pricing",
    // },
    {
      id: 120800,
      // enabled: roles.includes(120800),
      enabled: false,
      element: <EvaluationPricing />,
      path: "/course-management/evaluation-pricing",
    },
    {
      id: 120811,
      enabled: roles.includes(120811),
      element: <CoursePlatform />,
      path: "/course-management/course-platform",
    },
    {
      id: 120812,
      enabled: roles.includes(120812),
      element: <CoursesLectures />,
      path: "/course-management/courses",
    },
    {
      id: 120301,
      enabled: roles.includes(120301),
      element: <AddCourseCategory />,
      path: "/course-management/course-category/add",
    },
    {
      id: 120302,
      enabled: roles.includes(120302),
      element: <UpdateCourseCategory />,
      path: "/course-management/course-category/update/:id",
    },
    {
      id: 120303,
      enabled: roles.includes(120303),
      element: <SingleCourseCategory />,
      path: "/course-management/course-category/view/:id",
    },
    {
      id: 120300,
      enabled: roles.includes(120300),
      element: <ViewCourseCategory />,
      path: "/course-management/course-category",
    },
    {
      id: 120400,
      enabled: roles.includes(120400),
      element: <ViewSubCategories />,
      path: "/course-management/course-sub-category",
    },

    {
      id: 120400,
      // enabled: false,
      enabled: roles.includes(120400),
      element: <ViewSubCategoryPlatform />,
      path: "/course-management/sub-category-platform",
    },

    {
      id: 120501,
      enabled: roles.includes(120501),
      element: <AddGPSCourse />,
      path: "/course-management/gps-enable-course/add",
    },
    {
      id: 120502,
      enabled: roles.includes(120502),
      element: <UpdateGPSCourse />,
      path: "/course-management/gps-enable-course/update/:id",
    },
    {
      id: 120503,
      enabled: roles.includes(120503),
      element: <SingleGPSCourse />,
      path: "/course-management/gps-enable-course/view/:id",
    },
    {
      id: 120500,
      enabled: roles.includes(120500),
      element: <ViewGPSCourses />,
      path: "/course-management/gps-enable-course",
    },

    {
      id: 120600,
      enabled: roles.includes(120600),
      element: <ViewGPSAccessStudent />,
      path: "/course-management/gps-access-student",
    },
    {
      id: 120201,
      enabled: roles.includes(120201),
      element: <AddCourse />,
      path: "/course-management/courses/add",
    },
    {
      id: 120202,
      enabled: roles.includes(120202),
      element: <UpdateCourse />,
      path: "/course-management/courses/update/:id",
    },
    {
      id: 120200,
      enabled: roles.includes(120200),
      element: <ViewCourses />,
      path: "/course-management/courses",
    },
    {
      id: 120206,
      enabled: roles.includes(120206),
      element: <CourseSectionList />,
      path: "/course-management/courses/:courseId/sections",
    },
    {
      id: 120207,
      enabled: roles.includes(120207),
      element: <CourseLectureList />,
      path: "/course-management/courses/:courseId/lectures",
    },
    {
      id: 120208,
      enabled: roles.includes(120208),
      element: <SectionLectureList />,
      path: "/course-management/courses/:courseId/sections/:sectionId/lectures",
    },
    
    // ***************************************** Mentors Course  *****************************************
    {
      id: 652478,
      enabled: roles.includes(652478),
      element: <CourseMentors />,
      path: "/course-management/mentors-course",
    },
    {
      id: 120207,
      enabled: roles.includes(120207),
      element: <CourseLectureList />,
      path: "/course-management/mentors-course/:courseId/lectures",
    },
    {
      id: 652479,
      enabled: roles.includes(652479),
      element: <AssignedMentors />,
      path: "/course-management/mentors-course/assign-mentors/:id/:name",
    },
    
    // ************************************* Humanity Courses ***********************************************
    {
      id: 652488,
      enabled: roles.includes(652488),
      element: <HumanitesCourses />,
      path: "/course-management/humanity-courses",
    },
    {
      id: 652489,
      enabled: roles.includes(652489),
      element: <AddHumanitiesCourses />,
      path: "/course-management/humanity-courses/add",
    },
    {
      id: 652490,
      enabled: roles.includes(652490),
      element: <EditHumanitiesCourses />,
      path: "/course-management/humanity-courses/edit/:id",
    },
    
    // **************************** Website Management *****************************************
    // **************************** Banner *****************************************
    {
      id: 150101,
      enabled: roles.includes(150101),
      element: <AddBanner />,
      path: "/website-management/banners/add",
    },
    {
      id: 150102,
      enabled: roles.includes(150102),
      element: <UpdateBanner />,
      path: "/website-management/banners/update/:id",
    },
    {
      id: 150103,
      enabled: roles.includes(150103),
      element: <SingleBanner />,
      path: "/website-management/banners/view/:id",
    },
    {
      id: 150100,
      enabled: roles.includes(150100),
      element: <ViewBanners />,
      path: "/website-management/banners",
    },

    // **************************** Our Programs *****************************************
    {
      id: 150700,
      enabled: roles.includes(150700),
      element: <ViewOurPrograms />,
      path: "/website-management/our-programs",
    },
    {
      id: 150702,
      enabled: roles.includes(150702),
      element: <UpdateOurProgram />,
      path: "/website-management/our-programs/:program",
    },
    {
      id: 150706,
      enabled: roles.includes(150706),
      element: <RegionalPrograms />,
      path: "/website-management/our-programs/regional-program",
    },
    {
      id: 150707,
      enabled: roles.includes(150707),
      element: <AddProgram />,
      path: "/website-management/our-programs/regional-program/add",
    },
    {
      id: 150708,
      enabled: roles.includes(150708),
      element: <UpdateProgram />,
      path: "/website-management/our-programs/regional-program/update/:center",
    },

    // **************************** View Student Testimonials  *****************************************
    {
      id: 150300,
      enabled: roles.includes(150300),
      element: <ViewStudentTestimonials />,
      path: "/website-management/student-testimonials",
    },
    {
      id: 150301,
      enabled: roles.includes(150301),
      element: <AddStudentTestimonial />,
      path: "/website-management/student-testimonials/add",
    },
    {
      id: 150302,
      enabled: roles.includes(150302),
      element: <UpdateStudentTestimonial />,
      path: "/website-management/student-testimonials/update/:id",
    },
    {
      id: 150303,
      enabled: roles.includes(150303),
      element: <SingleStudentTestimonial />,
      path: "/website-management/student-testimonials/view/:id",
    },

    // **************************** Institute Toppers  *****************************************
    {
      id: 150801,
      enabled: roles.includes(150801),
      element: <AddTopper />,
      path: "/website-management/institute-toppers/add",
    },
    {
      id: 150802,
      enabled: roles.includes(150802),
      element: <UpdateTopper />,
      path: "/website-management/institute-toppers/update/:id",
    },
    {
      id: 150803,
      enabled: roles.includes(150803),
      element: <SingleTopper />,
      path: "/website-management/institute-toppers/view/:id",
    },
    {
      id: 150800,
      enabled: roles.includes(150800),
      element: <ViewToppers />,
      path: "/website-management/institute-toppers",
    },

    // **************************** Why Join Us  *****************************************
    {
      id: 150901,
      enabled: roles.includes(150901),
      element: <AddWhyJoin />,
      path: "/website-management/why-join-us/add",
    },
    {
      id: 150902,
      enabled: roles.includes(150902),
      element: <UpdateWhyJoin />,
      path: "/website-management/why-join-us/update/:id",
    },
    {
      id: 150903,
      enabled: roles.includes(150903),
      element: <SingleWhyJoin />,
      path: "/website-management/why-join-us/view/:id",
    },
    {
      id: 150900,
      enabled: roles.includes(150900),
      element: <ViewWhyJoin />,
      path: "/website-management/why-join-us",
    },

    // **************************** Teacher Display Order  *****************************************
    {
      id: 130300,
      enabled: roles.includes(130300),
      element: <ViewTeacherDisplayOrder />,
      path: "/website-management/teacher-display-order",
    },

    // **************************** Upcoming Exam And Course Display Order  *****************************************
    {
      id: 161005,
      enabled: roles.includes(161005),
      element: <UpcomingExamAndCourseDisplayOrder />,
      path: "/website-management/upcoming-exam-and-course-display-order",
    },

    {
      id: 151000,
      enabled: roles.includes(151000),
      element: <WelcomePopup />,
      path: "/website-management/welcome-popup",
    },

    // **************************** Site Settings  *****************************************
    {
      id: 161000,
      enabled: roles.includes(161000),
      element: <ViewSiteSetting />,
      path: "/website-management/site-setting",
    },

    // **************************** Site Settings  *****************************************
    {
      id: 161001,
      enabled: roles.includes(161001),
      element: <StaticUrlCourses />,
      path: "/website-management/static-url-courses",
    },

    // **************************** Subject Management  *****************************************
    {
      id: 240100,
      enabled: roles.includes(240100),
      element: <ViewSubjects />,
      path: "/subject-management/subject",
    },
    {
      id: 240200,
      enabled: roles.includes(240200),
      element: <ViewSubSubjects />,
      path: "/subject-management/sub-subject",
    },

    {
      id: 130103,
      enabled: roles.includes(130103),
      element: <SingleStudent />,
      path: "/student-management/all-students/view/:id",
    },
    {
      id: 130100,
      enabled: roles.includes(130100),
      element: <ViewStudents />,
      path: "/student-management/all-students",
    },
    // ****************************************** Student Details in Student Management *************************** *************************
    {
      id: 120116,
      enabled: roles.includes(120116),
      element: <SMStudentDetails />,
      path: "/student-management/student-details",
    },
    {
      id: 564812,
      enabled: roles.includes(564812),
      element: <GPSAccessStudentList />,
      path: "/student-management/gps-courses-student-list",
    },
    {
      id: 130301,
      enabled: roles.includes(130301),
      element: <AddTabCourseAccess />,
      path: "/student-management/tab-course-access/add",
    },
    {
      id: 130300,
      enabled: roles.includes(130300),
      element: <ViewTabCourseAccess />,
      path: "/student-management/tab-course-access",
    },
    {
      id: 130401,
      enabled: roles.includes(130401),
      element: <AddLiveLectureAccess />,
      path: "/student-management/live-lecture-access/add",
    },
    {
      id: 130400,
      enabled: roles.includes(130400),
      element: <ViewLiveLectureAccess />,
      path: "/student-management/live-lecture-access",
    },
    {
      id: 130501,
      enabled: roles.includes(130501),
      element: <AddTabLectureAccess />,
      path: "/student-management/tab-lecture-download-access/add",
    },
    {
      id: 130500,
      enabled: roles.includes(130500),
      element: <ViewTabLectureAccess />,
      path: "/student-management/tab-lecture-download-access/",
    },
    {
      id: 652480,
      enabled: roles.includes(652480),
      element: <CreateUserProfile />,
      path: "/student-management/create-student-profile",
    },
    {
      id: 130600,
      enabled: roles.includes(130600),
      element: <UpdateUserProfile />,
      path: "/student-management/update-profile",
    },
    {
      id: 130600,
      enabled: roles.includes(130600),
      element: <ViewStudentProfile />,
      path: "/student-management/view-profile/:mobile",
    },
    {
      id: 130200,
      enabled: roles.includes(130200),
      element: <CourseWiseStudent />,
      path: "/student-management/course-wise-student/",
    },
    {
      id: 200001,
      enabled: roles.includes(200001),
      element: <AddLecture />,
      path: "/lectures-management/add",
    },
    {
      id: 200002,
      enabled: roles.includes(200002),
      element: <UpdateLecture />,
      path: "/lectures-management/update/:id",
    },
    {
      id: 200000,
      enabled: roles.includes(200000),
      element: <ViewLectures />,
      path: "/lectures-management",
    },
    {
      id: 210001,
      enabled: roles.includes(210001),
      element: <AddLiveLecture />,
      path: "/live-lectures/add",
    },
    {
      id: 210002,
      enabled: roles.includes(210002),
      element: <UpdateLiveLecture />,
      path: "/live-lectures/update/:id",
    },
    {
      id: 210000,
      enabled: roles.includes(210000),
      element: <ViewLiveLectures />,
      path: "/live-lectures",
    },
    {
      id: 220001,
      enabled: roles.includes(220001),
      element: <AddGroupMeeting />,
      path: "/group-meetings/add",
    },
    {
      id: 220002,
      enabled: roles.includes(220002),
      element: <UpdateGroupMeeting />,
      path: "/group-meetings/update/:id",
    },
    {
      id: 9658546,
      enabled: roles.includes(9658546),
      element: <MobileNumber />,
      path: "/verify-mobile",
    },
    {
      id: 9658547,
      enabled: roles.includes(9658547),
      element: <EnrollStudentTab />,
      path: "/enroll-student-tab",
    },
    {
      id: 220000,
      enabled: roles.includes(220000),
      element: <ViewGroupMeetings />,
      path: "/group-meetings",
    },
    {
      id: 140100,
      enabled: roles.includes(140100),
      element: <AllTestListing />,
      path: "/test-management/test",
    },
    {
      id: 890003,
      enabled: roles.includes(890003),
      element: <AffiliateMacId />,
      path: "/affiliate-macids",
    },
    {
      id: 140101,
      enabled: roles.includes(140101),
      element: <AddTest />,
      path: "/test-management/test/add",
    },
    {
      id: 140102,
      enabled: roles.includes(140102),
      element: <UpdateTest />,
      path: "/test-management/test/update/:id",
    },
    {
      id: 140106,
      enabled: roles.includes(140106),
      element: <QuestionList />,
      path: "/test-management/test/:id",
    },
    {
      id: 140107,
      enabled: roles.includes(140107),
      element: <AddQuestion />,
      path: "/test-management/test/:id/add",
    },
    {
      id: 140108,
      enabled: roles.includes(140108),
      element: <UpdateQuestion />,
      path: "/test-management/test/:id/update/:questionId",
    },
    {
      id: 140200,
      enabled: roles.includes(140200),
      element: <TestBankListing />,
      path: "/test-management/test-bank",
    },
    {
      id: 140400,
      enabled: roles.includes(140400),
      element: <MapTestToCourses />,
      path: "/test-management/map-test-to-courses",
    },
    {
      id: 140300,
      enabled: roles.includes(140300),
      element: <ArtifactMappingList />,
      path: "/test-management/artifacts-mapping",
    },
    {
      id: 230000,
      enabled: roles.includes(230000),
      element: <ViewCoupons />,
      path: "/coupons-management",
    },
    {
      id: 230001,
      enabled: roles.includes(230001),
      element: <AddCoupon />,
      path: "/coupons-management/add",
    },
    {
      id: 230002,
      enabled: roles.includes(230002),
      element: <UpdateCoupon />,
      path: "/coupons-management/update/:id",
    },
    {
      id: 160100,
      enabled: roles.includes(160100),
      element: <ViewFaqs />,
      path: "/bank-management/faqs",
    },
    {
      id: 160101,
      enabled: roles.includes(160101),
      element: <AddFaqs />,
      path: "/bank-management/faqs/add",
    },
    {
      id: 160102,
      enabled: roles.includes(160102),
      element: <UpdateFaqs />,
      path: "/bank-management/faqs/update/:id",
    },
    {
      id: 160200,
      enabled: roles.includes(160200),
      element: <ViewCoreTakeaway />,
      path: "/bank-management/core-takeaway",
    },
    {
      id: 160201,
      enabled: roles.includes(160201),
      element: <AddCoreTakeaway />,
      path: "/bank-management/core-takeaway/add",
    },
    {
      id: 160202,
      enabled: roles.includes(160202),
      element: <UpdateCoreTakeaway />,
      path: "/bank-management/core-takeaway/update/:id",
    },
    // {
    //   enabled: false,
    //   element: <ViewMasterVideoBank />,
    //   path: "/bank-management/video-bank",
    // },
    // {
    //   enabled: false,
    //   element: <AddMasterVideoBank />,
    //   path: "/bank-management/video-bank/add",
    // },
    // {
    //   enabled: false,
    //   element: <UpdateMasterVideoBank />,
    //   path: "/bank-management/video-bank/update/:id",
    // },
    {
      id: 160300,
      enabled: roles.includes(160300),
      element: <ViewWebsiteVideoBank />,
      path: "/bank-management/website-video-bank",
    },
    {
      id: 160301,
      enabled: roles.includes(160301),
      element: <AddWebsiteVideoBank />,
      path: "/bank-management/website-video-bank/add",
    },
    {
      id: 160302,
      enabled: roles.includes(160302),
      element: <UpdateWebsiteVideoBank />,
      path: "/bank-management/website-video-bank/update/:id",
    },
    {
      id: 250200,
      enabled: roles.includes(250200),
      element: <ViewContact />,
      path: "/support-management/contact",
    },
    {
      id: 250100,
      enabled: roles.includes(250100),
      element: <ViewHelps />,
      path: "/support-management/helps",
    },
    {
      id: 170000,
      enabled: roles.includes(170000),
      element: <ViewCenters />,
      path: "/center-management",
    },
    {
      id: 170006,
      enabled: roles.includes(170006),
      element: <IntroVideoMapping />,
      path: "/center-management/link-intro-video/:centerId",
    },
    {
      id: 170007,
      enabled: roles.includes(170007),
      element: <BillingCoursesMapping />,
      path: "/center-management/billing-course-mapping/:centerId",
    },
    {
      id: 170008,
      enabled: roles.includes(170008),
      element: <FaqMappingForCenter />,
      path: "/center-management/faq-mapping/:centerId",
    },
    {
      id: 180000,
      enabled: roles.includes(180000),
      element: <UpcomingCourses />,
      path: "/upcoming-courses",
    },
    {
      id: 190000,
      enabled: roles.includes(190000),
      element: <ViewQuizs />,
      path: "/daily-quiz",
    },
    {
      id: 190001,
      enabled: roles.includes(190001),
      element: <AddQuiz />,
      path: "/daily-quiz/add",
    },
    {
      id: 190002,
      enabled: roles.includes(190002),
      element: <UpdateQuiz />,
      path: "/daily-quiz/update/:id",
    },
    {
      id: 260000,
      enabled: roles.includes(260000),
      element: <ViewNotifications />,
      path: "/notifications",
    },
    {
      id: 260001,
      enabled: roles.includes(260001),
      element: <AddNotification />,
      path: "/notifications/add",
    },
    {
      id: 310100,
      enabled: roles.includes(310100),
      element: <EvaluationSummary />,
      path: "/evaluation-management/evaluation-summary",
    },
    {
      id: 310200,
      enabled: roles.includes(310200),
      element: <ModeratorBestCopies />,
      path: "/evaluation-management/moderator-best-copies",
    },
    {
      id: 310300,
      enabled: roles.includes(310300),
      element: <BestCopies />,
      path: "/evaluation-management/best-copies",
    },
    {
      id: 310400,
      enabled: roles.includes(310400),
      element: <UploadAnswerSheet />,
      path: "/evaluation-management/upload-answer-sheet",
    },
    {
      id: 310500,
      enabled: roles.includes(310500),
      element: <EvaluatorDashboard />,
      path: "/evaluation-management/evaluator-dashboard",
    },
    {
      id: 310500,
      enabled: roles.includes(310500),
      element: <CoordinatorDashboard />,
      path: "/evaluation-management/coordinator-dashboard",
    },
    {
      id: 310600,
      enabled: roles.includes(310600),
      element: <EvaluatorBilling />,
      path: "/evaluation-management/evaluator-billing",
    },
    {
      id: 320000,
      enabled: roles.includes(320000),
      element: <Permissions />,
      path: "/permissions",
    },
    {
      id: 320000,
      enabled: roles.includes(320000),
      element: <AddKnoxTabletStudents />,
      path: "/add-knox-exception",
    },
    {
      id: 9658545,
      enabled: roles.includes(9658545),
      element: <OldStudentList />,
      path: "/old-student-list",
    },
    {
      id: 5124895,
      enabled: roles.includes(5124895),
      element: <StudentWatchingHours />,
      path: "/student-watching-hours",
    },
    {
      id: 5124896,
      enabled: roles.includes(5124896),
      element: <StudentCourseTestReport />,
      path: "/student-course-test-report",
    },
    {
      id: 9658547,
      enabled: roles.includes(9658547),
      element: <AddOldStudentDetail />,
      path: "/add-old-student-details",
    },
    {
      id: 9658500,
      enabled: roles.includes(9658500),
      element: <CourseAccessReport />,
      path: "/course-access-reports",
    },
    {
      id: 330100,
      enabled: roles.includes(330100),
      element: <LoginReport />,
      path: "/reports-management/login",
    },
    {
      id: 652486,
      enabled: roles.includes(652486),
      element: <StudentWithDefaultCourses />,
      path: "/reports-management/student-default-courses",
    },
    {
      id: 225566,
      enabled: roles.includes(225566),
      element: <AdmissionSummaryReport />,
      path: "/reports-management/admission-summary-report",
    },
    {
      id: 890001,
      enabled: roles.includes(890001),
      element: <KnoxApiErrorLog />,
      path: "/reports-management/knox-api-error-log",
    },
    {
      id: 890002,
      enabled: roles.includes(890002),
      element: <LectureAttemptLog />,
      path: "/reports-management/lecture-attempt-log",
    },
    {
      id: 890004,
      enabled: roles.includes(890004),
      element: <PiracyAttemptReport />,
      path: "/reports-management/piracy-attempt-report",
    },
    {
      id: 332211,
      enabled: roles.includes(332211),
      element: <AffiliateStudentCourseAccessReport />,
      path: "/reports-management/aff-stud-admm-course-access-report",
    },
    // {
    //   id: 890003,
    //   enabled: roles.includes(890003),
    //   name: "Affiliate MacID's",
    //   icon: <HistoryEduIcon fontSize="small" />,
    //   path: "/affiliate-macids",
    // },
    {
      id: 330200,
      enabled: roles.includes(330200),
      element: <LectureReport />,
      path: "/reports-management/lecture",
    },
    {
      id: 330300,
      enabled: roles.includes(330300),
      element: <TestReport />,
      path: "/reports-management/prelims-test",
    },
    {
      id: 330400,
      enabled: roles.includes(330400),
      element: <MainsTestReport />,
      path: "/reports-management/mains-test",
    },
    {
      id: 330500,
      enabled: roles.includes(330500),
      element: <ExceptionalLectureReport />,
      path: "/reports-management/exceptional-lecture",
    },
    {
      id: 330600,
      enabled: roles.includes(330600),
      element: <GroupMeetingReport />,
      path: "/reports-management/group-meeting",
    },

    {
      id: 330700,
      enabled: roles.includes(330700),
      element: <LectureDetailsHistory />,
      path: "/reports-management/lecture-details-history",
    },
    {
      id: 330800,
      enabled: roles.includes(330800),
      element: <LiveLectureDetailsHistory />,
      path: "/reports-management/live-lecture-details-history",
    },
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <CourseDetailVisitReport  />,
      path: "/reports-management/course-detail-visit-report",
    },
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <CourseSubscriptionVisitReport  />,
      path: "/reports-management/course-subscription-visit-report",
    },
    {
      id: 652472,
      enabled: roles.includes(652472),
      element: <StudentKYCDetails />,
      path: "/kyc-management/student-kyc-details",
    },
    {
      id: 652475,
      enabled: roles.includes(652475),
      element: <KYCVerifiedByStudentList />,
      path: "/kyc-management/kyc-verified-student",
    },
    {
      id: 652473,
      enabled: roles.includes(652473),
      element: <VerifiedKYCList />,
      path: "/kyc-management/verified-kyc-list",
    },
    {
      id: 652473,
      enabled: roles.includes(652473),
      element: <StudentKYCAction />,
      path: "/kyc-management/student-kyc-action/:mobile",
    },
    {
      id: 652474,
      enabled: roles.includes(652474),
      element: <RequestedKYCList />,
      path: "/kyc-management/requested-kyc-list",
    },
    {
      id: 996612,
      enabled: roles.includes(996612),
      element: <PGMaster />,
      path: "/pg-management/pg-master",
    },
    {
      id: 996613,
      enabled: roles.includes(996613),
      element: <AddPGMaster />,
      path: "/pg-management/pg-master/add-pg-master",
    },
    {
      id: 996614,
      enabled: roles.includes(996614),
      element: <EditPGMaster />,
      path: "/pg-management/pg-master/edit-pg-master/:id",
    },
    {
      id: 996616,
      enabled: roles.includes(996616),
      element: <AffiliatePG />,
      path: "/pg-management/affiliate-pg",
    },
    {
      enabled: true,
      element: <ChangePassword />,
      path: "/change-password",
    },
    {
      id: 120100,
      enabled: roles.includes(120100),
      element: <StudentAdmission />,
      path: "/billing/old-student-admissions",
    },
    {
      id: 120100,
      enabled: roles.includes(120100),
      element: <NewStudentAdmission />,
      path: "/billing/student-admissions",
    },
    {
      id: 120100,
      enabled: roles.includes(120100),
      element: <GSTReport />,
      path: "/billing/gst-report",
    },
    {
      id: 120111,
      enabled: roles.includes(120111),
      element: <StudentDetails />,
      path: "/billing/student-details",
    },
    {
      id: 120112,
      enabled: roles.includes(120112),
      element: <AffiliateBillingDetails />,
      path: "/billing/affiliate-billing-details",
    },
    {
      id: 666661,
      enabled: roles.includes(666661),
      element: <ImportData />,
      path: "/student-test-report/import-data",
    },
    {
      id: 666662,
      enabled: roles.includes(666662),
      element: <PSIRTestStudents />,
      path: "/student-test-report/psir-test-students",
    },
    {
      id: 666663,
      enabled: roles.includes(666663),
      element: <StudentCountByTest />,
      path: "/student-test-report/students-count",
    },
    {
      id: 666664,
      enabled: roles.includes(666664),
      element: <StudentAttendanceCountReport />,
      path: "/student-test-report/students-attendance-count",
    },
    {
      id: 666665,
      enabled: roles.includes(666665),
      element: <StudentDetailsReport />,
      path: "/student-test-report/students-details-report",
    },
    {
      id: 666667,
      enabled: roles.includes(666667),
      element: <StudentAttendanceReport />,
      path: "/student-test-report/students-attendance-report",
    },

    // ******************************* SEO Management ***************************************
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <SEODetails />,
      path: "/seo-details",
    },
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <AddSEODetails />,
      path: "/seo-details/add-seo-details",
    },
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <UpdateSEODetails />,
      path: "/seo-details/update-seo-details",
    },

    // ******************************* Mentor's Slot Management ***************************************
    // ******************************* All Mentors ***************************************
    {
      id: 70001,
      enabled: roles.includes(70001),
      element: <MentorList />,
      path: "/mentor-slot-management/all-mentors",
    },
    {
      id: 70000,
      enabled: roles.includes(70000),
      element: <MentorSlotList />,
      path: "/mentor-slot-management/all-mentors/book-slot",
    },
    {
      id: 70000,
      enabled: roles.includes(70000),
      element: <MentorSlotListbyMentorID />,
      path: "/mentor-slot-management/all-mentors/book-slot/:mentorid?/:name?",
    },
    //******************************* Minutes of Meeting Questions ***************************************
    // {
    //   id: 70001,
    //   enabled: roles.includes(70001),
    //   element: <MinutesOfMeetingQuestionsList />,
    //   path: "/mentor-slot-management/meeting-questions",
    // },

    //******************************* My Slots ***************************************
    {
      id: 70002,
      enabled: roles.includes(70002),
      element: <MySlots />,
      path: "/mentor-slot-management/my-slots",
    },

    // ******************************* My Appointments ***************************************
    {
      id: 70003,
      enabled: roles.includes(70003),
      element: <MyAppointmentList />,
      path: "/mentor-slot-management/my-appointments",
    },

    {
      id: 70003,
      enabled: roles.includes(70003),
      element: <EditStudentDetails />,
      path: "/mentor-slot-management/my-appointments/edit-student-details/:id",
    },

    {
      id: 70003,
      enabled: roles.includes(70003),
      element: <ViewMeetingDetails />,
      path: "/mentor-slot-management/my-appointments/view-meeting-details/:id",
    },

    // ******************************* Upcomming Appointments ***************************************
    {
      id: 70004,
      enabled: roles.includes(70004),
      element: <UpAppointmentList />,
      path: "/mentor-slot-management/Upcoming-appointment",
    },

    {
      id: 70003,
      enabled: roles.includes(70003),
      element: <EditStudentDetails />,
      path: "/mentor-slot-management/Upcoming-appointment/edit-student-details/:id",
    },

    // ******************************* Prelims Management ***************************************
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <FreeResourcePrelims />,
      path: "/prelims/prelims-subject",
    },
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <HotTopicPrelims />,
      path: "/prelims/hot-topic-prelims",
    },
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <AddHotTopic />,
      path: "/prelims/hot-topic-prelims/add-hot-topic",
    },
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <EditHotTopic />,
      path: "/prelims/hot-topic-prelims/edit-hot-topic",
    },

    {
      id: 600001,
      enabled: roles.includes(600001),
      element: <PrelimsPYQ />,
      path: "/prelims/gs-pyq",
    },
    {
      id: 600002,
      enabled: roles.includes(600002),
      element: <AddPrelimsPYQ />,
      path: "/prelims/gs-pyq/add-prelims-pyq",
    },
    {
      id: 600003,
      enabled: roles.includes(600003),
      element: <EditPrelimsPYQ />,
      path: "/prelims/gs-pyq/edit-prelims-pyq",
    },

    // ******************************* Mains Management ***************************************
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <FreeResourceMains />,
      path: "/mains/mains-pyq",
    },
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <AddMainsPYQ />,
      path: "/mains/mains-pyq/add-main-pyq",
    },
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <EditMainsPYQ />,
      path: "/mains/mains-pyq/edit-main-pyq",
    },

    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <MainsSubject />,
    //   path: "/mains/mains-subject",
    // },

    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <GS1 />,
    //   path: "/mains/gs-1",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <AddGS1 />,
    //   path: "/mains/gs-1/add-gs",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <EditGS1 />,
    //   path: "/mains/gs-1/edit-gs",
    // },

    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <GS2 />,
    //   path: "/mains/gs-2",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <AddGS2 />,
    //   path: "/mains/gs-2/add-gs",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <EditGS2 />,
    //   path: "/mains/gs-2/edit-gs",
    // },

    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <GS3 />,
    //   path: "/mains/gs-3",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <AddGS3 />,
    //   path: "/mains/gs-3/add-gs",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <EditGS3 />,
    //   path: "/mains/gs-3/edit-gs",
    // },

    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <GS4 />,
    //   path: "/mains/gs-4",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <AddGS4 />,
    //   path: "/mains/gs-4/add-gs",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <EditGS4 />,
    //   path: "/mains/gs-4/edit-gs",
    // },

    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <HotTopicsMains />,
    //   path: "/mains/hot-topic-mains",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <AddHotTopicsMains />,
    //   path: "/mains/hot-topic-mains/add-hot-topic",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <EditHotTopicsMains />,
    //   path: "/mains/hot-topic-mains/edit-hot-topic",
    // },

    // ******************************* Optional Management ***************************************
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <OptionalSubjects />,
    //   path: "/optional/optional-subject",
    // },

    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <OptionalPYQ />,
    //   path: "/optional/optional-pyq",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <AddOptionalPYQ />,
    //   path: "/optional/optional-pyq/add-optional-pyq",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <EditOptionalPYQ />,
    //   path: "/optional/optional-pyq/edit-optional-pyq",
    // },

    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <OptionalUpdates />,
    //   path: "/optional/optional-updates",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <AddOptionalUpdates />,
    //   path: "/optional/optional-updates/add-optional-updates",
    // },
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <EditOptionalUpdates />,
    //   path: "/optional/optional-updates/edit-optional-updates",
    // },

    // ******************************* Interview Management ***************************************
    // {
    //   id: 100000,
    //   enabled: roles.includes(100000),
    //   element: <FreeResourceInterview />,
    //   path: "/interview",
    // },

    // ******************************* Exceptional Lecture Management ***************************************
    {
      id: 150000,
      enabled: roles.includes(150000),
      element: <ExceptionalLectures />,
      path: "/exceptional-lecture-management",
    },

    // ******************************* Ip Access Management ***************************************
    {
      id: 888881,
      enabled: roles.includes(888881),
      element:<IpAccessList />,
      path: "/ip-access-management/access-list",
    },
    {
      id: 888882,
      enabled: roles.includes(888882),
      element:<IpAccessLog />,
      path: "/ip-access-management/access-log",
    },
  ].filter((route) => route.enabled);
  return routesData;
};
