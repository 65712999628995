import React, { useEffect, useState } from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import Header from "components/common/Header";
import LeftSideBar from "components/common/LeftSideBar";
import Login from "components/auth/Login";
import { routes } from "./sidebar";
import ResetPassword from "components/auth/ResetPassword";
import UserResetPassword from "components/account-verify/UserResetPassword";
import AccountVerify from "components/account-verify/AccountVerify";
import Error from "components/not-found/Error";
import ForgotPassword from "components/auth/ForgotPassword";
import useCookie from "hooks/useCookie";
import { Skeleton } from "@mui/material";
import { useAdminAuth } from "context/context";
import { getLocalConfig } from "utils";
import ErrorBoundary from "components/common/ErrorBoundary";
import IPAccessLogin from "components/auth/IPAccessLogin";
import IpAccessList from "pages/IpAccessManagement/IpAccessList/IpAccessList";

const IPAccessRouting = () => {
  const { cookies } = useCookie();
  const { roles } = useAdminAuth();
  const SidebarLayout = () => {
    const [fullMenu, setFullMenu] = useState(true);

    useEffect(() => {
      const menu = getLocalConfig("menu");
      if (menu !== "" && menu !== "undefined") {
        setFullMenu(menu);
      }
    }, []);
    return (
      <>
        <Header fullMenu={fullMenu} setFullMenu={setFullMenu} />
        <LeftSideBar fullMenu={fullMenu} />
        <ErrorBoundary fullMenu={fullMenu}>
          <React.Suspense
            fallback={
              <div className={fullMenu ? "WrapperArea" : "WrapperArea-Small"}>
                <div className="WrapperBox">
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={"100vh"}
                    animation="wave"
                  />
                </div>
              </div>
            }
          >
            <div className={fullMenu ? "WrapperArea" : "WrapperArea-Small"}>
              <Outlet />
            </div>
          </React.Suspense>
        </ErrorBoundary>
      </>
    );
  };

  //protected routes
  const ProtectedRoute = ({ children }) => {
    if (!cookies) {
      return <Navigate to="/" replace />;
    } else {
      return children;
    }
  };

  return (
    <>
      <Routes>
        <Route element={<SidebarLayout />}>
          {routes(roles).map(({ path, element }) => {
            return (
              <Route
                key={path}
                path={path}
                element={<ProtectedRoute>{element}</ProtectedRoute>}
              />
            );
          })}
        </Route>

        <Route path="/" element={<IPAccessLogin />} />

        {/* <Route path="/logout" element={<Logout />}></Route> */}
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<SidebarLayout />}>
        <Route path="/ip-access-management" element={<IpAccessList />} /></Route>
        

        {/* Verify for Api */}
        <Route
          path="/account_verification/:token"
          element={<AccountVerify />}
        />
        <Route
          path="/user/reset-password/:token"
          element={<UserResetPassword />}
        />

        <Route path="*" element={<Error />} />
      </Routes>

      {/* <Footer /> */}
    </>
  );
};

export default IPAccessRouting;
