import "./App.css";
import Routing from "./routes";
import IPAccessRouting from "./routes/ipaccessindex"
import toast, { Toaster } from "react-hot-toast";
import { HashRouter, BrowserRouter } from "react-router-dom";
import { AxiosInterceptor } from "config/axiosInstance";
import { disableReactDevTools, normalDecryptData } from "config/config";
import { CookiesProvider } from "react-cookie";
import { useAdminAuth } from "context/context";
import React, { useLayoutEffect, useState } from "react";
import axios from "axios";
import ErrorRouting from "routes/error";
import { getUserIpAddress, VerifyIpAddressAccess } from "services/auth";
import { Flag } from "@mui/icons-material";
import IPAccessLogin from "components/auth/IPAccessLogin";

const App = () => {
  const [ipFlag, setIPFlag] = useState(true);

  // disable react dev tools in production mode
  if (process.env.NODE_ENV === "production") disableReactDevTools();
  const { setRoles } = useAdminAuth();

  React.useEffect(() => {
    const roles = localStorage.getItem(process.env.REACT_APP_AUTH);
    if (roles) {
      let rolesData = roles ? JSON.parse(normalDecryptData(roles)) : [];
      setRoles(rolesData || []);
    }
    // eslint-disable-next-line
  }, []);

  // ***************************************** Handle Ip Address ***********************************************
  const handleIpAddress = async (ip) => {
    try {
      const res = await VerifyIpAddressAccess({
        ipaddress: ip,
      });
      if (res.code === 200) {
        setIPFlag(res.data.result);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  useLayoutEffect(() => {
    (async () => {
      try {
        const response = await getUserIpAddress();
        handleIpAddress(response.data.ip); // Access the `ip` property from the response data
      } catch (error) {
        console.error("Error fetching IP:", error);
      }
    })();
  }, []);

  return (
    <div className="App">
      {ipFlag ? (
        <BrowserRouter basename="/admin">
          {/* <HashRouter> */}
          <CookiesProvider>
            <AxiosInterceptor>
              <Routing />
              <Toaster position="top-right" />
            </AxiosInterceptor>
          </CookiesProvider>
          {/* </HashRouter> */}
        </BrowserRouter>
      ) : (
        <BrowserRouter basename="/admin">
          {/* <HashRouter> */}
          <CookiesProvider>
            <AxiosInterceptor>
              <ErrorRouting />
              <Toaster position="top-right" />
            </AxiosInterceptor>
          </CookiesProvider>
          {/* </HashRouter> */}
        </BrowserRouter>
      )}
      <BrowserRouter basename="/ip-access/credentials">
          {/* <HashRouter> */}
          <CookiesProvider>
            <AxiosInterceptor>
              <IPAccessRouting />
              <Toaster position="top-right" />
            </AxiosInterceptor>
          </CookiesProvider>
          {/* </HashRouter> */}
        </BrowserRouter>
    </div>
  );
};

export default App;
